import common from '@jurnee/common/src/locales/en/common.json';
import experience from '@jurnee/common/src/locales/en/experience.json';
import reviews from '@jurnee/common/src/locales/en/reviews.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import approvalRequests from './en/approvalRequests.json';
import billing from './en/billing.json';
import booking from './en/booking.json';
import bookings from './en/bookings.json';
import budgets from './en/budgets.json';
import collections from './en/collections.json';
import employees from './en/employees.json';
import entity from './en/entity.json';
import experiences from './en/experiences.json';
import externalCosts from './en/externalCosts.json';
import home from './en/home.json';
import navbar from './en/navbar.json';
import people from './en/people.json';
import registration from './en/registration.json';
import settings from './en/settings.json';
import surveys from './en/surveys.json';
import tasks from './en/tasks.json';
import teams from './en/teams.json';

export const resources = {
  en: {
    approvalRequests,
    billing,
    booking,
    bookings,
    budgets,
    collections,
    common,
    employees,
    entity,
    experience,
    experiences,
    externalCosts,
    home,
    navbar,
    people,
    registration,
    reviews,
    settings,
    surveys,
    tasks,
    teams
  }
};

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: [
    'approvalRequests',
    'billing',
    'booking',
    'bookings',
    'budgets',
    'collections',
    'common',
    'employees',
    'entity',
    'experience',
    'experiences',
    'externalCosts',
    'home',
    'registration',
    'reviews',
    'settings',
    'surveys',
    'tasks',
    'teams',
    'users'
  ],
  resources
});