import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, HStack, Select, useDisclosure, useToast } from '@chakra-ui/react';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { isAdmin } from '@jurnee/common/src/utils/user';
import { ChangeEvent, cloneElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/store';
import { updateBooking } from 'src/store/bookings/bookings.thunks';
import { getBudgetsFetchStatusSelector, getBudgetsSelector } from 'src/store/budgets/budgets.selectors';
import { getBudgets, getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { getTeamsFetchStatusSelector, getTeamsSelector } from 'src/store/teams/teams.selectors';
import { getTeams, getUserTeams } from 'src/store/teams/teams.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { PrimaryButton, SecondaryButton } from '../components/buttons';

interface Props {
  booking: Pick<BookingJSON, 'id' | 'team' | 'budget'>;
  children: React.ReactElement;
}

export function EditBookingDrawer({ booking, children }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation(['booking', 'common']);
  const toast = useToast();

  const dispatch = useAppDispatch();
  const user = useSelector(getUserSelector);
  const teamsFetchStatus = useSelector(getTeamsFetchStatusSelector);
  const budgetsFetchStatus = useSelector(getBudgetsFetchStatusSelector);
  const teams = useSelector(getTeamsSelector) ?? [];
  const budgets = useSelector(getBudgetsSelector) ?? [];

  const [budgetId, setBudgetId] = useState(booking.budget?.id);
  const [teamId, setTeamId] = useState(booking.team?.id);

  useEffect(() => {
    if (teamsFetchStatus === 'INITIAL') {
      dispatch(isAdmin(user) ? getTeams() : getUserTeams());
    }

    if (budgetsFetchStatus === 'INITIAL') {
      dispatch(isAdmin(user) ? getBudgets() : getUserBudgets());
    }
  }, [booking.id]);

  async function onSave() {
    try {
      await dispatch(
        updateBooking({
          data: {
            budgetId: budgetId || null,
            teamId: teamId || null
          },
          id: booking.id
        })
      );

      onClose();

      toast(getSuccessToast(t('toasts.update.success')));
    } catch(err) {
      toast(getErrorToast(t('toasts.update.error')));
    }
  }

  function onBudgetChange({ target: { value } }: ChangeEvent<HTMLSelectElement>) {
    setBudgetId(Number(value));
  }

  function onTeamChange({ target: { value } }: ChangeEvent<HTMLSelectElement>) {
    setTeamId(Number(value));
  }

  return (
    <>
      {cloneElement(children, { onClick: onOpen })}
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            {t('drawers.editBooking.header')}
          </DrawerHeader>
          <DrawerBody>
            <FormControl>
              <FormLabel>{t('drawers.editBooking.form.budget.label')}</FormLabel>
              <Select
                size="sm"
                bg="white"
                onChange={onBudgetChange}
                placeholder={t('drawers.editBooking.form.budget.placeholder')}
                value={budgetId}
              >
                {sortAlphabeticallyBy(budgets, 'name').map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={5}>
              <FormLabel>{t('drawers.editBooking.form.team.label')}</FormLabel>
              <Select
                size="sm"
                bg="white"
                onChange={onTeamChange}
                placeholder={t('drawers.editBooking.form.team.placeholder')}
                value={teamId}
              >
                {sortAlphabeticallyBy(teams, 'name').map(({ id, name }) => (
                  <option key={id} value={id}>{name}</option>
                ))}
              </Select>
            </FormControl>
          </DrawerBody>
          <DrawerFooter as={HStack} justifyContent="space-between" w="100%">
            <SecondaryButton size="sm" colorScheme="pink" onClick={onClose}>
              {t('common:buttons.close')}
            </SecondaryButton>
            <PrimaryButton size="sm" colorScheme="teal" onClick={onSave}>
              {t('common:buttons.save')}
            </PrimaryButton>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}