import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { Expand } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import bookingsApi from '../../api/bookings';
import { getBookings } from '../bookings/bookings.thunks';
import { RootState } from '../state';

export type PropositionSelectPayload = {
  bookingId: BookingJSON['id'];
  propositionsGroupId: PropositionsGroupJSON['id'];
  propositionId: PropositionJSON['id'];
}

export const selectProposition = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, PropositionSelectPayload, { state: RootState }>('PROPOSITIONS_SELECT', async ({ bookingId, propositionsGroupId, propositionId }, thunkAPI) => {
  try {
    const booking = await bookingsApi.selectProposition(bookingId, propositionsGroupId, propositionId);
    const searchParams = new URLSearchParams({ after: Date.now().toString() });

    thunkAPI.dispatch(getBookings({ scope: 'user' , searchParams }));

    return booking;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});