import { UserDetails } from '../entities/User';

const locale = typeof window !== 'undefined' && window.navigator ? window.navigator.language : 'en-US';

export function formatPrice(value: number, currency: string, options: Intl.NumberFormatOptions = {}) {
  return value.toLocaleString(locale, {
    style: 'currency',
    currency,
    ...options
  }).replace(' ', ' ');
}

export function formatPriceInUserCurrency(value: number, currency: string, user: Pick<UserDetails, 'currency'>, options: Intl.NumberFormatOptions = {}) {
  if (user.currency.id === currency) {
    return formatPrice(value, currency, options);
  }

  try {
    const exchange = user.currency.targetExchangeRates.find(({ sourceId }) => sourceId === currency);

    if (exchange === undefined) {
      throw new Error(`Missing exchange rate ${currency} => ${user.currency.id }`);
    }

    return formatPrice(value * exchange.rate, user.currency.id, options);
  } catch (err) {
    return formatPrice(value, currency, options);
  }
}

export function getCurrencyChar(currency: string) {
  const intl = new Intl.NumberFormat(locale, { style: 'currency', currency });
  const match = intl.formatToParts(1).find(({ type }) => type === 'currency');

  return match ? match.value : '€';
}