import { connect } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { RootState } from 'src/store/state';

interface Props {
  user: RootState['user'];
  company: RootState['company'];
}

function Help(props: Props): JSX.Element {
  const { boot } = useIntercom();

  const { company, user } = props;

  boot({
    alignment: 'left',
    horizontalPadding: 20,
    verticalPadding: 75,
    name: `${user.firstName} ${user.lastName}`,
    hideDefaultLauncher: true,
    userId: user.id.toString(),
    email: user.email,
    company: {
      companyId: company.data.id.toString(),
      name: company.data.name
    },
    customLauncherSelector: '#intercom-launcher'
  });

  return null;
}


function mapStateToProps(state: RootState): Props {
  return {
    user: state.user,
    company: state.company
  };
}

export default connect<Props, void>(mapStateToProps)(Help);