import { Box } from '@chakra-ui/react';
import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { SurveyJSON } from '@jurnee/common/src/entities/Survey';
import { Trans, useTranslation } from 'react-i18next';
import { SurveyAnswersDrawer } from 'src/drawers/SurveyAnswersDrawer';
import { ShareLinkModal } from 'src/modals/ShareLinkModal';

interface Props {
  survey: SurveyJSON;
  bookingCuid: BookingJSON['cuid'];
  onDelete(survey: SurveyJSON): void;
}

export function SurveyDotMenu({ survey, bookingCuid, onDelete }: Props) {
  const { t } = useTranslation('surveys', { keyPrefix: 'table.dotMenu' });

  return (
    <Box onClick={e => e.stopPropagation()}>
      <DotMenu size="sm" color="black" placement="bottom-end">
        <ShareLinkModal
          link={`${window.location.origin}/bookings/${bookingCuid}/surveys/${survey.id}`}
          title={t('shareALink.label')}
          description={t('shareALink.description')}
          eventName="share_survey"
        >
          <MenuItemWrapper icon="link" label={t('shareALink.label')} />
        </ShareLinkModal>
        <SurveyAnswersDrawer survey={survey}>
          <MenuItemWrapper icon="comment" label={t('viewAnswers')} />
        </SurveyAnswersDrawer>
        <ConfirmModal
          title={t('deleteConfirmation.title')}
          message={<Trans i18nKey="surveys:table.dotMenu.deleteConfirmation.message" values={{ surveyName: survey.name }} />}
          buttonLabel={t('delete')}
          onConfirm={() => onDelete(survey)}
        >
          <MenuItemWrapper icon="trash" label={t('delete')} />
        </ConfirmModal>
      </DotMenu>
    </Box>
  );
}