import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import { useTranslation } from 'react-i18next';
import { PartnerRequestCommentAttachmentJSON } from '../../../entities/PartnerRequestCommentAttachment';
import { Attachments } from './Attachments';

interface Comment {
  content: string;
  userType: 'JURNEE' | 'CUSTOMER' | 'PARTNER';
  createdAt: string
}

interface Props {
  comment: Comment;
  user: Pick<UserJSON, 'firstName' | 'lastName' | 'email'> | null;
  attachments?: Pick<PartnerRequestCommentAttachmentJSON, 'id' | 'filename' | 'path' | 'size'>[];
  onDownload?(attachmentId: number): void;
}

export function CommentCard({ comment, user, attachments, onDownload }: Props) {
  const { t } = useTranslation('common');

  return (
    <VStack
      w="100%"
      spacing={3}
      alignItems="flex-start"
      p={4}
      bgColor="white"
      borderRadius={4}
      border="1px solid"
      borderColor="gray.200"
    >
      <HStack spacing={2}>
        {
          comment.userType === 'JURNEE' ? (
            <>
              <Image w={22} h={22} src="/assets/images/favicon.png" />
              <Text fontWeight={700}>{t('jurneeTeam')}</Text>
            </>
          ) : (
            <>
              <Avatar size="xs" user={user} borderRadius="2px" />
              <Text fontWeight={700}>{getUserLabel(user)}</Text>
            </>
          )
        }
        <Text color="gray.400" fontSize={12}>{formatDate(new Date(comment.createdAt))}</Text>
      </HStack>

      <Text whiteSpace="pre-line" wordBreak="break-all">{comment.content}</Text>

      {
        attachments && attachments.length > 0 &&
          <Attachments
            attachments={attachments}
            onDownload={onDownload}
          />
      }
    </VStack>
  );
}