import { TeamUpsertBody } from '@jurnee/common/src/dtos/teams';
import { Team, TeamJSON, TeamRelationshipsJSON } from '@jurnee/common/src/entities/Team';
import { MostFavoriteList } from '@jurnee/common/src/entities/UserFavoriteExperience';
import { List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/teams';
import userApi from '../../api/user';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export interface TeamCreatePayload {
  team: TeamUpsertBody;
}

export interface TeamUpdatePayload extends TeamCreatePayload {
  teamId: Team['id'];
}

export const getTeams = createAsyncThunk<List<TeamJSON, never>, null, { state: RootState }>('TEAMS_FETCH', async (args, thunkAPI) => {
  try {
    const list = await api.getTeams();

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: 'An error occurred while retrieving teams', status: 'error' }));
    return thunkAPI.rejectWithValue([]);
  }
});

export const getUserTeams = createAsyncThunk<List<TeamJSON, TeamRelationshipsJSON>, null, { state: RootState }>('GET_USER_TEAMS', async (_args, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();
    const list = await userApi.getTeams(user.id);

    return list;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: 'An error occurred while retrieving teams', status: 'error' }));
    return thunkAPI.rejectWithValue(e.message);
  }
});

export const getTeamFavoriteExperiences = createAsyncThunk<MostFavoriteList, Team['id'], { state: RootState }>('TEAMS_GET_FAVORITE_EXPERIENCES', async (teamId) => {
  try {
    const favorites = await api.getFavoriteExperiences(teamId);

    return favorites;
  } catch (e) {
    return [];
  }
});

export const deleteTeam = createAsyncThunk<TeamJSON, Team['id'], { state: RootState }>('TEAMS_DELETE', async (teamId, thunkAPI) => {
  try {
    const team = await api.deleteTeam(teamId);

    return team;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: 'An error occurred while deleting your team', status: 'error' }));
  }
});

export const updateTeam = createAsyncThunk<TeamJSON, TeamUpdatePayload, { state: RootState }>('TEAMS_UPDATE', async ({ team, teamId }, thunkAPI) => {
  try {
    const updatedTeam = await api.updateTeam(teamId, team);

    return updatedTeam;
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message });
  }
});

export const createTeam = createAsyncThunk<TeamJSON, TeamCreatePayload, { state: RootState }>('TEAMS_CREATE', async ({ team }, thunkAPI) => {
  try {
    const createdTeam = await api.createTeam(team);

    return createdTeam;
  } catch (e) {
    return thunkAPI.rejectWithValue({ message: e.message });
  }
});