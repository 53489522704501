import { Box, Divider, Heading, HStack, Spacer, Text, VStack } from '@chakra-ui/react';
import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { Icon } from '@jurnee/common/src/components/Icon';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { PropositionJSON } from '@jurnee/common/src/entities/Proposition';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { sortAlphabeticallyBy, sortByDate } from '@jurnee/common/src/utils/arrays';
import { formatDate, getCurrentTimeZone } from '@jurnee/common/src/utils/dates';
import { getTotalPrice } from '@jurnee/common/src/utils/propositions';
import * as React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PrimaryButton } from 'src/components/buttons';
import ItemCard from 'src/components/ItemCard';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';
import ItemCommentDrawer from 'src/drawers/ItemCommentDrawer';
import { PropositionSelectPayload } from 'src/store/propositions/propositions.thunks';
import { RootState } from 'src/store/state';

interface OwnProps {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  propositionsGroup: PropositionsGroupJSON;
  onSelectProposition(payload: PropositionSelectPayload): void;
}

interface StateProps {
  company: RootState['company'];
  user: RootState['user'];
}

type Props = OwnProps & StateProps & WithTranslation;

interface State {
  isSelectingProposition: boolean;
  selectedPropositionId: number;
}

class PropositionsStep extends React.Component<Props> {

  state: State = {
    isSelectingProposition: false,
    selectedPropositionId: null
  };

  onSelectProposition = async (propositionId: PropositionJSON['id']) => {
    this.setState({ ...this.state, propositionId, isSelectingProposition: true, selectedPropositionId: propositionId });

    await this.props.onSelectProposition({
      bookingId: this.props.booking.id,
      propositionsGroupId: this.props.propositionsGroup.id,
      propositionId
    });

    this.setState({ ...this.state, isSelectingProposition: false });
  };

  getPropositionItems({ propositionsItems }: PropositionJSON) {
    const items = sortByDate(propositionsItems, 'from')
      .map((propositionItem, i) => {
        const product = this.props.relationships.products.find(({ id }) => id === propositionItem.productId);
        const experience = this.props.relationships.experiences.find(({ id }) => id === product?.experienceId);

        return (
          <ItemCard
            key={propositionItem.id}
            stackProps={i > 0 ? { pt: 5, borderTop: '1px solid var(--chakra-colors-blue-50)' } : null}
            item={propositionItem}
            experience={experience}
            currency={product ? product.currency : 'EUR'}
          >
            { propositionItem.comment && <ItemCommentDrawer heading={propositionItem.description} comment={propositionItem.comment} /> }
          </ItemCard>
        );
      });

    return (
      <VStack w="100%" spacing={5}>
        {items}
      </VStack>
    );
  }

  getPropositionComment({ comment }: PropositionJSON) {
    if (!comment) {
      return null;
    }

    return [
      <Box
        key={0}
        w="100%"
        fontSize={14}
        color="gray.400"
        sx={{ 'a': { textDecoration: 'underline' } }}
        dangerouslySetInnerHTML={{ __html: comment }}
      />,
      <Divider key={1} />
    ];
  }

  getExpirationDate(proposition: PropositionJSON) {
    const expiredAt = new Date(proposition.expiredAt);
    const date = formatDate(expiredAt, { timeZone: getCurrentTimeZone() });

    if (new Date() >= expiredAt) {
      return (
        <HStack spacing={2}>
          <Icon icon="warning" color="red.500" size={5} />
          <Text><Trans i18nKey="booking:steps.propositions.expired" values={{ date }} /></Text>
        </HStack>
      );
    }

    return (
      <HStack spacing={2}>
        <Icon icon="warning" color="orange.400" size={5} />
        <Text><Trans i18nKey="booking:steps.propositions.validUntil" values={{ date }} /></Text>
      </HStack>
    );
  }

  get propositions() {
    const propositions = sortAlphabeticallyBy(this.props.propositionsGroup.propositions, 'name').map((proposition: PropositionJSON) => {
      const total = getTotalPrice(proposition);

      return (
        <VStack key={proposition.id} w="100%" p={5} bg="white" border="1px solid" borderColor="blue.50" borderRadius={8} spacing={4}>
          <HStack w="100%" justifyContent="space-between">
            <Text variant="label">{proposition.name}</Text>

            <Text variant="label">
              <PriceInUserCurrency value={total} currency={'EUR'} />
            </Text>
          </HStack>

          <Divider />

          {this.getPropositionComment(proposition)}

          {this.getPropositionItems(proposition)}

          <Divider />

          <HStack w="100%">
            { proposition.expiredAt && this.getExpirationDate(proposition) }

            <Spacer />

            <ConfirmModal
              title={this.props.t('modals.selectPropositionConfirmation.title')}
              message={this.props.t('modals.selectPropositionConfirmation.message')}
              buttonLabel={this.props.t('common:buttons.validate')}
              onConfirm={() => this.onSelectProposition(proposition.id)}
              buttonColor="teal"
            >
              <PrimaryButton
                colorScheme="blue"
                size="xs"
                fontWeight={500}
                isDisabled={proposition.id !== this.state.selectedPropositionId && this.state.isSelectingProposition}
                isLoading={proposition.id === this.state.selectedPropositionId && this.state.isSelectingProposition}
                flexShrink={0}
              >
                {this.props.t('steps.propositions.select')}
              </PrimaryButton>
            </ConfirmModal>
          </HStack>
        </VStack>
      );
    });

    return (
      <VStack w="100%" alignItems="flex-start" spacing={5}>
        {propositions}
      </VStack>
    );
  }

  get helpText() {
    return (
      <VStack w="100%" spacing={5}>
        <Heading size="md" w="100%">{this.props.t('steps.propositions.helpText.heading')}</Heading>
        <VStack w="100%" p={5} spacing={5} bg='white' borderRadius={8} border="1px solid" borderColor="blue.50" >
          <Text w="100%" fontSize={14} color="gray.400">
            {this.props.t('steps.propositions.helpText.description')}
          </Text>
          <PrimaryButton id="intercom-launcher" size="sm">{this.props.t('steps.propositions.helpText.button')}</PrimaryButton>
        </VStack>
      </VStack>
    );
  }

  render() {
    return (
      <HStack w="100%" alignItems="flex-start" spacing={5}>
        <VStack w="100%" alignItems="flex-start" spacing={8}>
          <VStack w="100%" alignItems="flex-start" spacing={5}>
            <Heading size="md">{this.props.t('steps.propositions.packagesOptions')}</Heading>
            {this.propositions}
          </VStack>
        </VStack>

        <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={8}>
          {this.helpText}
        </VStack>
      </HStack>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    company: state.company,
    user: state.user
  };
}

export default connect<StateProps, void, OwnProps, RootState>(
  mapStateToProps
)(withTranslation('booking')(PropositionsStep));