import { getTimeZones, TimeZone } from '@vvo/tzdb';
import { addDays, addMonths, differenceInMinutes, DurationUnit, endOfMonth, endOfQuarter, endOfYear, format, formatDistanceToNowStrict, formatDuration, Interval, intervalToDuration, isBefore, isPast, isWithinInterval, startOfMonth, startOfQuarter, startOfYear, subDays, subMonths, subQuarters, subYears } from 'date-fns';
import { fromZonedTime, getTimezoneOffset } from 'date-fns-tz';
import { enGB } from 'date-fns/locale/en-GB';

interface FormatDateOptions {
  displayTime: boolean;
  displayTimeZone: boolean;
  timeZone: Intl.DateTimeFormatOptions['timeZone'];
}

export type Timezone = TimeZone;

export function add(date: Date, amount: number) {
  return addDays(date, amount);
}

export function subtract(date: Date, amount: number) {
  return subDays(date, amount);
}

export function isDatePast(date: Date) {
  return isPast(date);
}

export function getDateAtMidnight(date: Date): Date {
  const dateAtMidnight = new Date(date);
  dateAtMidnight.setHours(0,0,0,0);

  return dateAtMidnight;
}

export function getNextDay(date: Date): Date {
  const nextDay = new Date(date);
  nextDay.setDate(date.getDate() + 1);

  return nextDay;
}

export function inNDaysDate(n: number) {
  const date = new Date();
  date.setDate(date.getDate() + n);

  return date;
}

export function inNDaysAtMidnightDate(n: number) {
  const date = inNDaysDate(n);
  return getDateAtMidnight(date);
}

export function nDaysAgoDate(n: number) {
  const date = new Date();
  date.setDate(date.getDate() - n);

  return date;
}

export function getPreviousDay(date: Date): Date {
  const previousDay = new Date(date);
  previousDay.setDate(date.getDate() - 1);

  return previousDay;
}

export function formatDate(date: Date, options: Partial<FormatDateOptions> = {}) {
  const format: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  };

  if (options.displayTime) {
    format.hour = '2-digit';
    format.minute = '2-digit';
    format.hourCycle = 'h24';
  }

  if (options.timeZone) {
    format.timeZone = options.timeZone;
  }

  if (options.displayTimeZone) {
    format.timeZoneName = 'short';
  }

  return date.toLocaleString('en-GB', format);
}

export function formatZonedDateTime(date: Date, timezone: string) {
  return date.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timezone
  });
}

export function formatTime(date: Date, options: Partial<FormatDateOptions> = {}) {
  const format: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24'
  };

  if (options.timeZone) {
    format.timeZone = options.timeZone;
  }

  if (options.displayTimeZone) {
    format.timeZoneName = 'short';
  }

  return date.toLocaleTimeString('en-GB', format);
}

export function formatTimeShort(date: Date) {
  return date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function getZonedDate(date: Date, timezone: string) {
  return fromZonedTime(date, timezone);
}

export function getTimeZonesList() {
  return getTimeZones().sort((a, b) => a.name.localeCompare(b.name));
}

export function getEndOfHalfYear(date: Date) {
  const month = date.getMonth();

  if (month < 6) {
    const start = startOfYear(date);
    const halfYear = addMonths(start, 5);

    return getEndOfMonth(halfYear);
  }

  return getEndOfYear(date);
}

export function getEndOfMonth(date: Date) {
  return endOfMonth(date);
}

export function getEndOfQuarter(date: Date) {
  return endOfQuarter(date);
}

export function getEndOfYear(date: Date) {
  return endOfYear(date);
}

export function getStartOfHalfYear(date: Date) {
  const month = date.getMonth();

  if (month > 5) {
    const end = endOfYear(date);
    const halfYear = subMonths(end, 5);

    return getStartOfMonth(halfYear);
  }

  return getStartOfYear(date);
}

export function getStartOfMonth(date: Date) {
  return startOfMonth(date);
}

export function getStartOfQuarter(date: Date) {
  return startOfQuarter(date);
}

export function getStartOfYear(date: Date) {
  return startOfYear(date);
}

export type DateRange = Interval;

export function isInDateRange(date: Date | null, range: Interval): boolean {
  return date instanceof Date && isWithinInterval(date, range);
}

export function getIntervalToDuration(start: Date, end: Date) {
  return intervalToDuration({ start, end });
}

export function convertToTimezone(input: string | Date, sourceTimezone: string, targetTimezone: string) {
  const date = new Date(input);
  const sourceOffset = getTimezoneOffset(sourceTimezone, date);
  const targetOffset = getTimezoneOffset(targetTimezone, date);

  return new Date(date.getTime() + sourceOffset - targetOffset);
}

export function getStartOfMonthDateString() {
  const now = new Date();
  const nowUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));

  return nowUtc.toJSON().slice(0, 10);
}

export function getDuration(start: Date, end: Date, format: DurationUnit[] = ['years', 'months', 'days', 'hours']) {
  const duration = intervalToDuration({ start, end });

  return formatDuration(duration, { format, delimiter: ', ' });
}

export function distanceToNow(date: Date, suffix = true): string {
  return formatDistanceToNowStrict(date, { addSuffix: suffix });
}

export function isDateBefore(a: Date, b: Date) {
  return isBefore(a, b);
}

export function formatShortWithTime(date: Date): string {
  return format(date, 'dd-MM-yyyy - p');
}

export function formatDateShort(date: Date) {
  return format(date, 'dd-MM-yyyy');
}

export function getTimesFromPeriodicity(periodicity: string): { from: number | null, to: number | null } {
  let from: Date | null = null;
  let to: Date | null = null;

  switch (periodicity) {
  case 'current_month':
    from = getStartOfMonth(new Date());
    break;
  case 'current_quarter':
    from = getStartOfQuarter(new Date());
    break;
  case 'current_semester':
    from = getStartOfHalfYear(new Date());
    break;
  case 'current_year':
    from = getStartOfYear(new Date());
    break;
  case 'previous_month': {
    const previousMonth = subMonths(new Date(), 1);

    from = getStartOfMonth(previousMonth);
    to = getEndOfMonth(previousMonth);
    break;
  }
  case 'previous_quarter': {
    const previousQuarter = subQuarters(new Date(), 1);

    from = getStartOfQuarter(previousQuarter);
    to = getEndOfQuarter(previousQuarter);
    break;
  }
  case 'previous_semester': {
    const startOfSemester = getStartOfHalfYear(new Date());
    const previousSemester = subMonths(startOfSemester, 1);

    from = getStartOfHalfYear(previousSemester);
    to = getEndOfHalfYear(previousSemester);
    break;
  }
  case 'previous_year': {
    const previousYear = subYears(new Date(), 1);

    from = getStartOfYear(previousYear);
    to = getEndOfYear(previousYear);
    break;
  }
  }

  return {
    from: from ? from.getTime() : null,
    to: to ? to.getTime() : null
  };
}

export function getDurationInMins(from: string, to: string) {
  return Math.abs(differenceInMinutes(new Date(from), new Date(to)));
}

export function getDayOfWeekPrefix(date: Date) {
  return format(new Date(date), 'eee', { locale: enGB });
}

export function getDayOfMonth(date: Date) {
  return new Date(date).getDate().toString().padStart(2, '0');
}

export function getMonthAndYear(date: Date) {
  return format(new Date(date), 'MMMM yyyy', { locale: enGB });
}

export function getCurrentTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}