{
  "header": {
    "title": "Settings",
    "tabs": {
      "budgets": "Budgets",
      "payment": "Payment",
      "catalog": "Catalog",
      "approval": "Approval",
      "sso": "Single Sign On",
      "integrations": "Integrations"
    }
  },
  "approval": {
    "drawer": {
      "newApprovalProcess": "New approval process",
      "title": "Approval Process",
      "form": {
        "name": {
          "label": "Approval process name"
        },
        "processNumberRequired": {
          "label": "Purchase order number required",
          "switchLabel": "Yes"
        },
        "approvers": {
          "label": "Reviewers",
          "description": "All these users will have to approve bookings",
          "placeholder": "Select a user"
        },
        "users": {
          "label": "Who is this approval system for?",
          "description": "Bookings of these users have to be approved",
          "placeholder": "Select a user"
        }
      },
      "toasts": {
        "create": {
          "error": "An error occurred while creating your approval process",
          "success": "Approval process created"
        },
        "update": {
          "error": "An error occurred while updating your approval process",
          "success": "Approval process updated"
        },
        "approvalProcessAlreadyAssociated": {
          "error": "{{userName}} is already associated to the following approval process: {{approvalProcessName}}"
        }
      }
    },
    "emptyState": {
      "heading": "Want to set up an automated approval process?",
      "description": "Start here to create the first approval process by choosing who needs to review which booking requests. Reviewers are automatically notified and can approve or reject booking requests as required."
    },
    "delete": {
      "title": "Delete approval process",
      "buttonLabel": "Delete process",
      "text": "Are you sure you want to delete <strong>{{name}}</strong> approval process?",
      "toasts": {
        "error": "An error occurred while deleting your approval process",
        "success": "Approval process deleted"
      }
    },
    "edit": {
      "buttonLabel": "Edit process"
    },
    "table": {
      "name": "Approval Process",
      "rules": "Rules",
      "processNumber": "P.O. Number",
      "users": "Members",
      "rulePrefix": "All bookings require an approval from"
    }
  },
  "budgets": {
    "drawer": {
      "createBudget": "Create new budget",
      "createTitle": "New budget",
      "updateTitle": "Edit budget",
      "form": {
        "amount": {
          "label": "Amount",
          "individual": "per person"
        },
        "definitionType": {
          "label": "Type",
          "options": {
            "GLOBAL": "Global",
            "INDIVIDUAL": "Individual"
          }
        },
        "frequency": {
          "label": "Renewal frequency",
          "options": {
            "MONTHLY": "Monthly",
            "QUARTERLY": "Quarterly",
            "SEMIANNUALLY": "Semiannually",
            "YEARLY": "Yearly"
          }
        },
        "name": {
          "label": "Name"
        },
        "users": {
          "INDIVIDUAL": {
            "label": "People",
            "placeholder": "Search someone to add to the budget",
            "noUsers": "There is currently nobody in this budget",
            "existingBudget": "Already in a budget"
          },
          "GLOBAL": {
            "label": "Organizers",
            "placeholder": "Search an organizer to add to the budget",
            "noUsers": "There is currently no organizers in this budget"
          }
        }
      },
      "warnings": {
        "noTeams": {
          "text": "You don't have any teams set up, and budget allocation is determined by team size.",
          "button": "Create my first team"
        }
      },
      "toasts": {
        "create": {
          "error": "An error occurred while creating your budget",
          "success": "Budget created"
        },
        "update": {
          "error": "An error occurred while updating your budget",
          "success": "Budget updated"
        }
      }
    },
    "emptyState": {
      "heading": "No budget defined yet",
      "description": "Allocate budgets to your team, track expenses in real time, and give your colleagues more autonomy over their spending!"
    },
    "overview": {
      "expensesCommitted": "expenses committed this year",
      "plannedAnnualBudget": "planned annual budget",
      "noCommitment": "Commit a spending amount & unlock discounts on your events 🎉 <br/><br/> Contact us <intercom>here</intercom> or at <mailto>hello@jurnee.io</mailto>"
    },
    "toasts": {
      "yearlyBudget" :{
        "error": "An error occurred while retrieving your budgets spending"
      }
    },
    "filters": {
      "definitionTypes": {
        "all": "All types",
        "GLOBAL": "Global",
        "INDIVIDUAL": "Individual"
      },
      "name": {
        "placeholder": "Search a budget"
      },
      "emptyState": {
        "heading": "No budgets found",
        "description": "We can't find any budgets matching these filters"
      }
    }
  },
  "payment": {
    "paymentMethod": {
      "title": "What payment method will your teams use?",
      "description": "Wire transfer payments will require a quote signature<br/>or the set up of an in-app <approvalTab>approval process</approvalTab>",
      "options": {
        "CREDIT_CARD": "Credit card",
        "WIRE_TRANSFER": "Wire transfer"
      }
    },
    "billingEmails": {
      "title": "Automatic invoice forward",
      "description": "Invoices will automatically be sent to these email adresses<br/>You can add several email addresses seperated by spaces"
    }
  },
  "sso": {
    "toasts": {
      "create": {
        "error": "An error occurred while creating the configuration",
        "success": "Configuration created"
      },
      "update": {
        "error": "An error occurred while updating the configuration",
        "success": "Configuration updated"
      }
    },
    "heading": "SSO integration",
    "help": {
      "heading": "Need Help?",
      "text": "We'll guide you through the process of creating and configuring a new SSO integration for your Jurnee account.",
      "button": "Open documentation"
    },
    "form": {
      "clientId": "Client ID",
      "clientSecret": "Client Secret",
      "issuer": "Issuer",
      "provider": "Provider"
    }
  },
  "integrations": {
    "connected": "Connected",
    "slack": {
      "label": "Slack",
      "description": "Receive notifications, register or decline event invitations, accept or reject approval requests directly in Slack."
    },
    "errors": {
      "TOKEN_FAILED": "Unable to recover access token"
    }
  }
}
