{
  "buttons": {
    "apply": "Apply",
    "cancel": "Cancel",
    "close": "Close",
    "connect": "Connect",
    "decline": "Decline",
    "delete": "Delete",
    "download": "Download",
    "edit": "Edit",
    "export": "Export",
    "publish": "Publish",
    "remove": "Remove",
    "save": "Save",
    "submit": "Submit",
    "validate": "Validate",
    "view": "View"
  },
  "login": {
    "sso": {
      "buttons": {
        "continue": "Continue with SSO",
        "login": "Login with this email"
      },
      "errors": {
        "AUTHORIZATION_FAILED": "Single sign on is not configured for your workspace or you do not have access to Jurnee",
        "TOKEN_FAILED": "Unable to recover your connection token"
      }
    }
  },
  "yes": "Yes",
  "no": "No",
  "or": "or",
  "settings": "Settings",
  "jurneeTeam": "Jurnee team",
  "people": "people",
  "fields": {
    "accountNumber": {
      "label": "Account number"
    },
    "bankCode": {
      "label": "Bank code"
    },
    "bicSwift": {
      "label": "BIC/SWIFT"
    },
    "billingAddress": {
      "label": "Billing address"
    },
    "budget": {
      "label": "Budget",
      "placeholder": "Select a budget"
    },
    "city": {
      "label": "City"
    },
    "citySearch": {
      "error": "An error occured while searching cities",
      "placeholder": "Paris, Barcelone, Berlin…"
    },
    "comment": {
      "label": "Comment",
      "placeholder": "Write your comment…"
    },
    "country": {
      "label": "Country"
    },
    "date": {
      "label": "Date",
      "placeholder": "Select a date",
      "tooltip": "Tight deadlines can be challenging, we will do our best to make it happen but can't promise anything."
    },
    "description": {
      "label": "Description"
    },
    "email": {
      "label": "Email",
      "placeholder": "john.doe@acme.com"
    },
    "eventName": {
      "label": "Event name",
      "placeholder": "What is your event's name?",
      "error": "Event name is required"
    },
    "fileDocument": {
      "attachmentTypes": "PDF, JPEG, or CSV",
      "documentTypes": "PDF, JPEG or PNG",
      "label": "Choose a file to upload"
    },
    "firstName": {
      "label": "First name",
      "placeholder": "First name"
    },
    "iban": {
      "label": "IBAN number"
    },
    "lastName": {
      "label": "Last name",
      "placeholder": "Last name"
    },
    "legalCompanyName": {
      "label": "Legal company name"
    },
    "location": {
      "label": "Select location",
      "helperText": "Please select the city for the event timezone"
    },
    "name": {
      "label": "Name"
    },
    "participants": {
      "label": "Number of participants",
      "placeholder": "Number of participants"
    },
    "phoneNumber": {
      "helper": "In international format, ex: +22 607 123 4567",
      "label": "Phone number"
    },
    "postalCode": {
      "label": "Postal code"
    },
    "preferredCurrency": {
      "label": "Preferred currency"
    },
    "provider": {
      "label": "Select a provider",
      "helperText": "Please select location first",
      "placeholder": "Search provider by name"
    },
    "registrationNumber": {
      "label": "Registration number"
    },
    "routingNumber": {
      "label": "ABA routing number"
    },
    "state": {
      "label": "State"
    },
    "street": {
      "label": "Street"
    },
    "time": {
      "label": "Time",
      "placeholder": "Select a time"
    },
    "usersSearch": {
      "placeholder": "Search a user"
    },
    "vatNumber": {
      "label": "VAT number"
    },
    "virtualEvent": {
      "label": "It's a virtual event"
    },
    "website": {
      "label": "Website"
    }
  },
  "status": {
    "approved": "approved",
    "canceled": "canceled",
    "open": "to pay",
    "paid": "paid",
    "pending": "pending",
    "rejected": "rejected",
    "signed": "signed"
  },
  "link": "Link",
  "virtual": "Virtual",
  "excludedTax": "excl. tax"
}