import { Box, HStack, Link, StackProps, Text, VStack } from '@chakra-ui/react';
import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { ItemAddress } from '@jurnee/common/src/components/ItemAddress';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { ExperienceJSON } from '@jurnee/common/src/entities/Experience';
import { ProductJSON } from '@jurnee/common/src/entities/Product';
import { getItemTimezone } from '@jurnee/common/src/utils/bookingsItems';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { Trans, useTranslation } from 'react-i18next';
import EditBookingItemDrawer from 'src/drawers/EditBookingItemDrawer';

interface Props extends StackProps {
  item: BookingItemJSON;
  experience: ExperienceJSON | null;
  product: ProductJSON | null;
  onRemove(bookingItemId: number): void;
}

type Steps = 'RECEIVED' | 'CONTACTING' | 'WAITING' | 'CREATING';

type StepsProgress = Record<Steps, number>;

function getFormattedDate(item: BookingItemJSON) {
  const date = new Date(item.from);

  return formatDate(date, {
    displayTime: true,
    displayTimeZone: true,
    timeZone: getItemTimezone(item)
  });
}

const ONE_HOUR = 1000 * 60 * 60;
const TWO_HOURS = ONE_HOUR * 2;
const ONE_DAY = ONE_HOUR * 24;
const TWO_DAYS = ONE_DAY * 2;

function getStepsProgress(item: BookingItemJSON): StepsProgress {
  const createdAt = new Date(item.createdAt);
  const now = new Date();
  const duration = now.getTime() - createdAt.getTime();

  const progress = {
    RECEIVED: 100,
    CONTACTING: 100,
    WAITING: 100,
    CREATING: 40
  };

  if ((duration - ONE_DAY) < TWO_DAYS) {
    progress.CREATING = 0;
    progress.WAITING = (duration - ONE_DAY) / TWO_DAYS * 100;
  }

  if ((duration - TWO_HOURS) < ONE_DAY) {
    progress.WAITING = 0;
    progress.CONTACTING = (duration - TWO_HOURS) / ONE_DAY * 100;
  }

  if (duration < TWO_HOURS) {
    progress.CONTACTING = 0;
    progress.RECEIVED = duration / TWO_HOURS * 100;
  }

  return progress;
}

function Progress({ progress }: { progress: StepsProgress }) {
  return (
    <HStack spacing={1} w="100%" h="3px">
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.RECEIVED}%`} h="100%"/>
      </Box>
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.CONTACTING}%`} h="100%"/>
      </Box>
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.WAITING}%`} h="100%"/>
      </Box>
      <Box bg="gray.200" h="100%" w="25%">
        <Box bg="black" w={`${progress.CREATING}%`} h="100%"/>
      </Box>
    </HStack>
  );
}

export function ProcessingItemCard({ item, onRemove, product, experience, ...props }: Props) {
  const { t } = useTranslation(['booking', 'common']);

  const progress = getStepsProgress(item);
  const current = Object.entries(progress).reduce((out, [key, value]) => value > 0 ? key : out, 'RECEIVED');
  const isPayViaJurne = !!item.partnerDocumentId;
  const label = t(`${isPayViaJurne ? 'payViaJurnee' : 'processing'}.${current}`);

  return (
    <VStack spacing={2} w="100%" {...props}>
      <HStack justifyContent="space-between" w="100%">
        <Text lineHeight="16px" fontWeight={700} noOfLines={1} maxW="60%">{item.description}</Text>
        <Text lineHeight="16px">{label}</Text>
      </HStack>
      <Progress progress={progress}/>
      {item.comment && <Text lineHeight="1.6em" color="gray.400" w="100%" noOfLines={2}>{item.comment}</Text>}
      <Text lineHeight="16px" color="gray.400" w="100%">{getFormattedDate(item)}</Text>
      <ItemAddress item={item} lineHeight="16px" w="100%" color="gray.400" noOfLines={1} />
      <HStack w="100%" justifyContent="space-between">
        <Text lineHeight="16px" color="gray.400" w="100%">{item.participants} {t('common:people')}</Text>
        <HStack spacing={5}>
          <EditBookingItemDrawer bookingItem={item} experience={experience} product={product}>
            <Link fontSize={14} lineHeight="16px" color="blue.400">{t('common:buttons.edit')}</Link>
          </EditBookingItemDrawer>
          <ConfirmModal
            title={t('modals.removeItem.title')}
            message={<Trans i18nKey='booking:modals.removeItem.message' values={{ name: item.description }} />}
            buttonLabel={t('common:buttons.remove')}
            onConfirm={() => onRemove(item.id)}
          >
            <Link fontSize={14} lineHeight="16px" color="pink.400">{t('common:buttons.remove')}</Link>
          </ConfirmModal>
        </HStack>
      </HStack>
    </VStack>
  );
}