import { Divider, FormControl, HStack, Text, Textarea, VStack } from '@chakra-ui/react';
import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { ApprovalRequestJSON } from '@jurnee/common/src/entities/ApprovalRequest';
import { BookingJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { UserJSON } from '@jurnee/common/src/entities/User';
import { hasInitialApprovalResponses } from '@jurnee/common/src/utils/approvalRequests';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import AwaitingInfoCard from 'src/components/Booking/AwaitingInfoCard';
import ProcessNumber from 'src/components/Booking/ProcessNumber';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import { ProcessNumberInput } from 'src/components/ProcessNumberInput';
import { PrimaryButton } from 'src/components/buttons';
import ApprovalResponses from '../../ApprovalResponses';

interface OwnProps {
  approvalProcess: ApprovalProcessJSON;
  approvalRequest: ApprovalRequestJSON | null;
  bookingsInvoices: BookingInvoiceJSON[];
  isSelfApprovalEnabled: boolean;
  processNumber: BookingJSON['processNumber'];
  users: UserJSON[];
  onSubmit(data: ApprovalRequestCreateBody): Promise<void>;
  disabled: boolean;
}

type Props = OwnProps & WithTranslation;

type State = {
  data: ApprovalRequestCreateBody;
  isSubmitting: boolean;
};

class ApprovalRequestCard extends React.Component<Props> {

  state: State = {
    data: {
      processNumber: this.props.processNumber,
      comment: this.props.approvalRequest ? this.props.approvalRequest.comment : null
    },
    isSubmitting: false
  };

  onSubmit = async () => {
    this.setState({ ...this.state, isSubmitting: true });

    await this.props.onSubmit(this.state.data);

    this.setState({ ...this.state, isSubmitting: false });
  };

  onChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      ...this.state,
      data: {
        ...this.state.data,
        [target.name]: target.value
      }
    });
  };

  get approvers() {
    const approverIds = this.props.approvalProcess.approvalRules.map(({ approverId }) => approverId);

    return this.props.users.filter(({ id }) => approverIds.includes(id));
  }

  get approversList() {
    return this.approvers.map((approver, i) => {
      return (
        <HStack key={i} w="100%" pl={3} paddingY="10px" borderLeft="4px solid" borderColor="gray.200" justifyContent="space-between">
          <Text fontSize={14} noOfLines={1} fontWeight={700}>{getUserLabel(approver)}</Text>
          <Text fontSize={14} noOfLines={1} color="gray.400">{approver.email}</Text>
        </HStack>
      );
    });
  }

  get isProcessNumberRequired() {
    if (this.props.approvalRequest) {
      return false;
    }

    if (!this.props.isSelfApprovalEnabled) {
      return false;
    }

    return this.props.approvalProcess.processNumberRequired;
  }

  get processNumber() {
    if (this.props.approvalRequest) {
      if (!this.props.processNumber) {
        return null;
      }

      return <ProcessNumber label={this.props.t('booking:processNumber')} processNumber={this.props.processNumber} />;
    }

    return <ProcessNumberInput
      defaultValue={this.state.data.processNumber}
      isDisabled={!!this.props.approvalRequest}
      isRequired={this.isProcessNumberRequired}
      onChange={this.onChange}
    />;
  }

  get approversSection() {
    if (!this.props.approvalRequest && this.props.isSelfApprovalEnabled) {
      return null;
    }

    return (
      <VStack w="100%" spacing={5}>
        { this.props.approvalRequest ? <ApprovalResponses approvalRequest={this.props.approvalRequest} users={this.props.users} /> : this.approversList }
      </VStack>
    );
  }

  get commentForm() {
    if (this.props.approvalRequest) {
      return null;
    }

    if (this.props.isSelfApprovalEnabled) {
      return null;
    }

    return (
      <FormControl id='comment'>
        <Textarea
          name="comment"
          height="70px"
          fontSize="14px"
          resize="none"
          placeholder={this.props.approvalRequest ? null :this.props.t('form.comment.placeholder')}
          _placeholder={{ color: 'gray.400' }}
          onChange={this.onChange}
          defaultValue={this.state.data.comment}
          isDisabled={!!this.props.approvalRequest}
          _disabled={{ color: 'gray.400', bgColor: 'gray.50', cursor: 'not-allowed' }}
        />
      </FormControl>
    );
  }

  get priceDetails() {
    return getBookingInvoicesTotalDetails(this.props.bookingsInvoices);
  }

  get submitButton() {
    if (this.props.approvalRequest && hasInitialApprovalResponses(this.props.approvalRequest)) {
      return <AwaitingInfoCard label={this.props.t('form.awaitingApproval')} />;
    }

    if (this.props.isSelfApprovalEnabled) {
      return (
        <ConfirmModal
          buttonColor="teal"
          title={this.props.t('approvalResponse.confirmationModal.approve.title')}
          message={this.props.t('approvalResponse.confirmationModal.approve.message')}
          buttonLabel={this.props.t('approvalResponse.approve')}
          onConfirm={this.onSubmit}
        >
          <PrimaryButton
            w="100%"
            type="submit"
            colorScheme="blue"
            isLoading={this.state.isSubmitting}
            isDisabled={this.isProcessNumberRequired && !this.state.data.processNumber}
          >
            { this.props.t('form.approve') }
          </PrimaryButton>
        </ConfirmModal>
      );
    }

    return (
      <PrimaryButton
        w="100%"
        type="submit"
        colorScheme='blue'
        onClick={this.onSubmit}
        isLoading={this.state.isSubmitting}
        isDisabled={this.props.disabled}
      >
        {this.props.t('form.submit')}
      </PrimaryButton>
    );
  }

  get description() {
    if (this.props.approvalRequest) {
      return this.props.t('form.requireApproval.submitted.description');
    }

    return this.props.t(`form.requireApproval.${this.props.isSelfApprovalEnabled ? 'selfApproval' : 'create'}.description`);
  }

  render() {
    return (
      <VStack w="100%" spacing={5}>
        { this.processNumber }

        <VStack w="100%" alignItems="flex-start" spacing={3}>
          <VStack w="100%" alignItems="flex-start" spacing={1}>
            <Text fontSize={16} fontWeight={700}>{this.props.t(`form.requireApproval.label`)}</Text>
            <Text fontSize={14} color="gray.400">{this.description}</Text>
          </VStack>

          { this.approversSection }

          { this.commentForm }
        </VStack>

        <Divider />

        <BookingPriceDetailsCard
          subtotal={this.priceDetails.subtotal}
          totalTax={this.priceDetails.totalTax}
          totalDiscount={this.priceDetails.totalDiscount}
          total={this.priceDetails.total}
          currency={this.priceDetails.currency}
          showCurrencyWarning={true}
        />

        { this.submitButton }
      </VStack>
    );
  }

}

export default withTranslation('approvalRequests')(ApprovalRequestCard);