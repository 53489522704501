import { Box, VStack } from '@chakra-ui/react';
import { FETCH_STATUS, FetchStatus } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BookingsInvoicesSearchParams } from '@jurnee/common/src/entities/BookingInvoice';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BookingsInvoicesFilters from 'src/components/BookingsInvoicesFilters';
import { TrackEventOptions, trackEvent } from 'src/store/analytics/analytics.thunks';
import { getBookingsInvoices } from 'src/store/bookingsInvoices/bookingsInvoices.thunks';
import { getBudgetsByDefinitionTypeSelector, getBudgetsFetchStatusSelector } from 'src/store/budgets/budgets.selectors';
import { RootState } from 'src/store/state';
import { getTeamsFetchStatusSelector, teamsSelectors } from 'src/store/teams/teams.selectors';
import { BookingsInvoicesTable } from './BookingsInvoicesTable';

interface StateProps {
  bookingsInvoices: RootState['bookingsInvoices'];
  budgets: BudgetJSON[];
  budgetsFetchStatus: FetchStatus;
  entities: RootState['entities'];
  teams: TeamJSON[];
  teamsFetchStatus: FetchStatus;
}

interface FilterProps {
  budgetId: number | null;
  periodicity: string;
}


interface DispatchProps {
  getBookingsInvoices(filters: BookingsInvoicesSearchParams): void;
  trackEvent(opts: TrackEventOptions): void;
}

type Props = DispatchProps & StateProps & FilterProps & WithTranslation;

class BookingsInvoices extends React.Component<Props> {

  onChange = (filters: BookingsInvoicesSearchParams) => {
    this.props.getBookingsInvoices(filters);

    this.props.trackEvent({
      name: 'applied_bookings_invoices_filters',
      properties: {
        budgetIds: !!filters.budgetIds,
        entityIds: !!filters.entityIds,
        from: !!filters.from,
        teamIds: !!filters.teamIds,
        to: !!filters.to,
      }
    });
  };

  get loader() {
    return (
      <Box p={8}>
        <Loader h={400} />
      </Box>
    );
  }

  get bookingsInvoices() {
    if (this.props.bookingsInvoices.status !== FETCH_STATUS.FETCHED) {
      return this.loader;
    }

    return <BookingsInvoicesTable bookingsInvoices={this.props.bookingsInvoices.list} />;
  }

  render() {
    if (this.props.budgetsFetchStatus !== FETCH_STATUS.FETCHED) {
      return this.loader;
    }

    if (this.props.entities.status !== FETCH_STATUS.FETCHED) {
      return this.loader;
    }

    if (this.props.teamsFetchStatus !== FETCH_STATUS.FETCHED) {
      return this.loader;
    }

    return (
      <VStack w="100%" spacing={5}>
        <BookingsInvoicesFilters
          bookingsInvoices={this.props.bookingsInvoices.list}
          budgets={this.props.budgets}
          entities={this.props.entities.list}
          teams={this.props.teams}
          budgetId={this.props.budgetId}
          periodicity={this.props.periodicity}
          onChange={this.onChange}
        />
        {this.bookingsInvoices}
      </VStack>
    );
  }

}

function mapStateToProps(state: RootState): StateProps {
  return {
    bookingsInvoices: state.bookingsInvoices,
    budgets: getBudgetsByDefinitionTypeSelector(state, 'GLOBAL'),
    budgetsFetchStatus: getBudgetsFetchStatusSelector(state),
    entities: state.entities,
    teams: teamsSelectors.selectAll(state),
    teamsFetchStatus: getTeamsFetchStatusSelector(state),
  };
}

const mapDispatchToProps: DispatchProps = {
  getBookingsInvoices,
  trackEvent,
};

export default connect<StateProps, DispatchProps, void, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('billing')(BookingsInvoices));