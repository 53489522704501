import { useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { ApprovalRequestCreateBody } from '@jurnee/common/src/dtos/approvalRequests';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { PropositionsGroupJSON } from '@jurnee/common/src/entities/PropositionsGroup';
import { getBookingStatus, isBookingCanceled } from '@jurnee/common/src/utils/bookings';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/store';
import { createApprovalRequest } from 'src/store/approvalRequests/approvalRequests.thunks';
import { CreditCardCommitPayload, commitByCreditCard, getBooking } from 'src/store/bookingDetails/bookingDetails.thunks';
import { deleteBookingItem } from 'src/store/bookingsItems/bookingsItems.thunks';
import { PropositionSelectPayload, selectProposition } from 'src/store/propositions/propositions.thunks';
import { getBookingStep } from 'src/utils/booking';
import { CanceledStep } from './CanceledStep';
import CommitmentStep from './CommitmentStep';
import ConfirmationStep from './ConfirmationStep';
import FinishedStep from './FinishedStep';
import { PlanningStep } from './PlanningStep';
import PropositionsStep from './PropositionsStep';
import WaitingEventStep from './WaitingEventStep';

interface Props {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  invoiceToPay: BookingInvoiceJSON;
  propositionsGroup: PropositionsGroupJSON;
  entity: EntityJSON;
}

export function BookingSteps({ booking, relationships, invoiceToPay, entity, propositionsGroup }: Props) {
  const { t } = useTranslation('booking');
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [currentStep, setCurrentStep] = useState(getBookingStep(getBookingStatus(booking, relationships)));

  async function onRemoveBookingItem(bookingItemId: BookingItemJSON['id']) {
    try {
      await dispatch(deleteBookingItem({ bookingId: booking.id, bookingItemId }));
      toast(getSuccessToast(t('toasts.removeItem.success')));
    } catch (err) {
      toast(getErrorToast(t('toasts.removeItem.error')));
    }
  }

  async function onSetupIntentCreated(data: CreditCardCommitPayload['data']) {
    await dispatch(commitByCreditCard({
      invoiceId: invoiceToPay.providerInvoiceId,
      bookingId: booking.id,
      data
    }));

    dispatch(getBooking(booking.id));
  }

  async function onSelectProposition(payload: PropositionSelectPayload) {
    try {
      await dispatch(selectProposition(payload));
      setCurrentStep('COMMITMENT');
    } catch (err) {
      toast(getErrorToast(t('toasts.selectProposition.error')));
    }
  }

  async function onApprovalRequestSubmit(data: ApprovalRequestCreateBody) {
    try {
      await dispatch(createApprovalRequest({
        id: booking.id,
        data
      }));

      toast(getSuccessToast(t('approvalRequests:form.toasts.success')));

      dispatch(getBooking(booking.id));
    } catch (err) {
      toast(getErrorToast(t('approvalRequests:form.toasts.error')));
    }
  }

  if (isBookingCanceled(booking)) {
    return <CanceledStep />;
  }

  switch (currentStep) {
  case 'PLANNING':
    if (!invoiceToPay) {
      return null;
    }

    if (propositionsGroup) {
      return (
        <PropositionsStep
          booking={booking}
          relationships={relationships}
          propositionsGroup={propositionsGroup}
          onSelectProposition={onSelectProposition}
        />
      );
    }

    return <PlanningStep
      booking={booking}
      relationships={relationships}
      bookingInvoice={invoiceToPay}
      onRemoveBookingItem={onRemoveBookingItem}
      onNext={() => setCurrentStep('COMMITMENT')}
    />;
  case 'COMMITMENT':
    if (!invoiceToPay) {
      return null;
    }

    return <CommitmentStep
      booking={booking}
      relationships={relationships}
      bookingInvoice={invoiceToPay}
      entity={entity}
      onApprovalRequestSubmit={onApprovalRequestSubmit}
      onSetupIntentCreated={onSetupIntentCreated}
      onRemoveBookingItem={onRemoveBookingItem}
    />;
  case 'CONFIRMATION':
    return <ConfirmationStep booking={booking} relationships={relationships} entity={entity}
    />;
  case 'WAITING_EVENT':
    return <WaitingEventStep booking={booking} relationships={relationships} entity={entity}
    />;
  case 'FINISHED':
    return <FinishedStep booking={booking} relationships={relationships} entity={entity} />;
  default:
    return <Loader />;
  }
}