import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const base = defineStyle({
  color: 'white'
});

const black = defineStyle({
  ...base,
  bgColor: 'black'
});

const blue = defineStyle({
  ...base,
  bgColor: 'blue.400'
});

const gray = defineStyle({
  ...base,
  bgColor: 'gray.400'
});

const orange = defineStyle({
  ...base,
  bgColor: 'orange.400'
});

const teal = defineStyle({
  ...base,
  bgColor: 'teal.300'
});

const pink = defineStyle({
  ...base,
  bgColor: 'pink.500'
});

const white = defineStyle({
  bgColor: 'white',
  color: 'black'
});

const sm = defineStyle({
  borderRadius: '2px',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '8px',
  padding: '4px 8px'
});

const md = defineStyle({
  borderRadius: '4px',
  fontSize: '10px',
  fontWeight: 700,
  lineHeight: '8px',
  padding: '4px 6px'
});

export const Badge = defineStyleConfig({
  variants: {
    black,
    blue,
    gray,
    orange,
    pink,
    teal,
    white
  },
  sizes: {
    sm,
    md
  },
  defaultProps: {
    size: 'sm'
  }
});