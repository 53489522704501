import { Box, Divider, Heading, HStack, keyframes, Link, Text, VStack } from '@chakra-ui/react';
import { Icon } from '@jurnee/common/src/components/Icon';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { EntityJSON } from '@jurnee/common/src/entities/Entity';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getBookingInvoicesTotalDetails } from '@jurnee/common/src/utils/bookingInvoices';
import { getCommitmentType } from '@jurnee/common/src/utils/bookings';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import AwaitingInfoCard from 'src/components/Booking/AwaitingInfoCard';
import ProcessNumber from 'src/components/Booking/ProcessNumber';
import BookingPriceDetailsCard from 'src/components/BookingPriceDetailsCard';
import EntityCard from 'src/components/EntityCard';
import EventSchedule from 'src/components/EventSchedule';
import ApprovalResponses from '../ApprovalResponses';
import { BookingDetailsStepper } from '../BookingDetailsStepper';
import Quotes from '../Quotes';

interface OwnProps {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  entity: EntityJSON;
}

type Props = OwnProps & WithTranslation;

class ConfirmationStep extends React.PureComponent<Props> {

  get processNumber() {
    if (!this.props.booking.processNumber) {
      return null;
    }

    return <ProcessNumber
      label={this.props.t('processNumber')}
      processNumber={this.props.booking.processNumber}
    />;
  }

  get bookingApprovedLabel() {
    return <Text fontSize={14} fontWeight={700} lineHeight="16px">{this.props.t(`steps.confirmation.bookingApproved`)}</Text>;
  }

  get approvalResponses() {
    const [approvalRequest] = sortByDate(this.props.relationships.approvalRequests, 'createdAt', 'desc');

    return (
      <VStack w="100%" alignItems="flex-start" spacing={3}>
        { this.bookingApprovedLabel }

        <ApprovalResponses approvalRequest={approvalRequest} users={this.props.relationships.users} />
      </VStack>
    );
  }

  get quotes() {
    return (
      <VStack w="100%" alignItems="flex-start" spacing={3}>
        { this.bookingApprovedLabel }

        <Quotes quotes={this.props.relationships.quotes} bookingsInvoices={this.props.relationships.bookingsInvoices} />;
      </VStack>
    );
  }

  get commitment() {
    const commitmentType = getCommitmentType(this.props.relationships);

    switch(commitmentType) {
    case 'APPROVAL_REQUEST':
      return this.approvalResponses;
    case 'QUOTE':
      return this.quotes;
    default:
      return null;
    }
  }

  get priceDetails() {
    return getBookingInvoicesTotalDetails(this.props.relationships.bookingsInvoices);
  }

  get animatedIcon() {
    return <Icon
      icon="circleDots"
      animation={`${keyframes('from { transform: rotate(0deg); } to { transform: rotate(360deg); }')} infinite linear 4s`}
    />;
  }

  render() {
    return (
      <HStack w="100%" spacing={5} alignItems="flex-start">
        <VStack w="100%" alignItems="flex-start" spacing={8}>
          <VStack w="100%" alignItems="flex-start" spacing={3}>
            <HStack spacing={3}>
              <Heading size="md">{this.props.t('steps.confirmation.checkingAvailability.heading')}</Heading>
              { this.animatedIcon }
            </HStack>

            <VStack w="100%" p={5} bg="white" border="1px solid" borderColor="blue.50" borderRadius={8} spacing={5}>
              <Text w="100%" fontSize={14} whiteSpace="pre-line">
                <Trans i18nKey='booking:steps.confirmation.checkingAvailability.description' />
              </Text>

              <Divider />

              <Text w="100%" fontSize={14} color="gray.400">
                <Trans
                  i18nKey='booking:helpText'
                  components={{ intercom: <Link color="black" id="intercom-launcher" />}}
                />
              </Text>
            </VStack>
          </VStack>

          <EventSchedule
            heading={this.props.t('schedule.heading')}
            bookingsItems={this.props.booking.bookingsItems}
            experiences={this.props.relationships.experiences}
            products={this.props.relationships.products}
            currency={this.props.relationships.bookingsInvoices[0].currency}
            editDisabled={true}
          />
        </VStack>

        <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={3}>
          <Heading size="md">{this.props.t('invoicing.heading')}</Heading>

          <Box bg="white" border="1px solid" borderColor="blue.50" borderRadius={8}>
            <BookingDetailsStepper step="CONFIRMATION" />

            <VStack p={5} spacing={5}>
              <EntityCard entity={this.props.entity} />

              { this.processNumber }

              { this.commitment }

              <Divider />

              <BookingPriceDetailsCard
                subtotal={this.priceDetails.subtotal}
                totalTax={this.priceDetails.totalTax}
                totalDiscount={this.priceDetails.totalDiscount}
                total={this.priceDetails.total}
                currency={this.priceDetails.currency}
                showCurrencyWarning={false}
              />

              <AwaitingInfoCard label={this.props.t('steps.confirmation.awaitingConfirmation')} />

              <Text fontSize={14} color="gray.400" whiteSpace="pre-line" textAlign="center">
                {this.props.t('steps.confirmation.notChargedYet')}
              </Text>
            </VStack>
          </Box>
        </VStack>
      </HStack>
    );
  }

}

export default withTranslation('booking')(ConfirmationStep);