import { Flex, Heading, HStack, VStack } from '@chakra-ui/react';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingInvoiceJSON } from '@jurnee/common/src/entities/BookingInvoice';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { useTranslation } from 'react-i18next';
import EventSchedule from 'src/components/EventSchedule';
import { ExploreCard } from 'src/components/ExploreCard';
import { isBookingEditDisabled } from 'src/utils/booking';
import { BookingSummary } from './BookingSummary';
import { ExploreSection } from './ExploreSection';

interface Props {
  booking: BookingJSON;
  relationships: BookingRelationshipsJSON;
  bookingInvoice: BookingInvoiceJSON;
  onRemoveBookingItem(bookingItemId: BookingItemJSON['id']): void;
  onNext(): void;
}

export function PlanningStep(props: Props) {
  const { t } = useTranslation('booking');

  if (props.booking.bookingsItems.length === 0) {
    return (
      <Flex h="100%" flexDirection="column" alignItems="center" justifyContent="center">
        <ExploreCard maxW={712} booking={props.booking} />
      </Flex>
    );
  }

  return (
    <HStack w="100%" alignItems="initial" spacing={5}>
      <VStack w="100%" spacing={8}>
        <EventSchedule
          heading={t('schedule.heading')}
          bookingsItems={props.booking.bookingsItems}
          experiences={props.relationships.experiences}
          products={props.relationships.products}
          currency={props.bookingInvoice.currency}
          editDisabled={isBookingEditDisabled(props.booking, props.relationships)}
          onRemove={props.onRemoveBookingItem}
        />

        <ExploreSection booking={props.booking} />
      </VStack>

      <VStack w="100%" minW={380} maxW={380} alignItems="stretch" spacing={3}>
        <Heading size="md">{t('steps.planning.summary.heading')}</Heading>

        <BookingSummary
          booking={props.booking}
          relationships={props.relationships}
          currency={props.bookingInvoice.currency}
          onNext={props.onNext}
        />
      </VStack>
    </HStack>
  );
}