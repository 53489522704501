import { AvatarGroup, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import Avatar from '@jurnee/common/src/components/Avatar';
import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { EmptyState } from '@jurnee/common/src/components/EmptyState';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { sortAlphabeticallyBy } from '@jurnee/common/src/utils/arrays';
import { Trans, useTranslation } from 'react-i18next';
import TeamDrawer from 'src/drawers/TeamDrawer';

interface Props {
  teams: TeamJSON[];
  hasFilters: boolean;
  onDelete(team: TeamJSON): void;
}

interface TeamProps {
  team: TeamJSON;
}

function Members({ team }: TeamProps) {
  if (team.usersTeams.length === 0) {
    return '-';
  }

  const usersAvatars = team.usersTeams.map(({ user }, index) => {
    const shadowProps = index > 0 ? { shadow: '0 0 4px 0 rgba(0, 0, 0, 0.15)' } : {};
    return <Avatar key={user.id} user={user} borderRadius="full" {...shadowProps} />;
  });

  return (
    <AvatarGroup size="xs" max={8} spacing={-1.5}>
      { usersAvatars }
    </AvatarGroup>
  );
}

function EditMenu(props: TeamProps & Props) {
  const { t } = useTranslation('teams', { keyPrefix: 'dotMenu' });
  const { team, onDelete } = props;

  return (
    <DotMenu size="sm" color="black" placement="bottom-end">
      <TeamDrawer team={team}>
        <MenuItemWrapper icon="edit" label={t('edit')} />
      </TeamDrawer>

      <ConfirmModal
        title={t('deleteConfirmation.title')}
        message={<Trans i18nKey='teams:dotMenu.deleteConfirmation.message' values={{ name: team.name }} />}
        buttonLabel={t('delete')}
        onConfirm={() => onDelete(team)}
      >
        <MenuItemWrapper icon="trash" label={t('delete')} />
      </ConfirmModal>
    </DotMenu>
  );
}

function Header() {
  const { t } = useTranslation('teams', { keyPrefix: 'table' });

  return (
    <Thead>
      <Tr>
        <Th width="40%">{t('name')}</Th>
        <Th width="40%">{t('members')}</Th>
        <Th>{t('headcount')}</Th>
        <Th w={8}></Th>
      </Tr>
    </Thead>
  );
}

function Body(props: Props) {
  const { t } = useTranslation('teams', { keyPrefix: 'emptyState' });
  const i18nKey = props.hasFilters ? 'filters' : 'default';

  if (props.teams.length === 0) {
    return (
      <Tbody>
        <Tr>
          <Td p={16} colSpan={5}>
            <EmptyState
              icon="listSearch"
              heading={t(`${i18nKey}.heading`)}
              description={t(`${i18nKey}.description`)}
            />
          </Td>
        </Tr>
      </Tbody>
    );
  }

  return (
    <Tbody>
      {
        sortAlphabeticallyBy(props.teams, 'name').map(team => {
          return (
            <Tr key={team.id}>
              <Td>{team.name}</Td>
              <Td><Members team={team} /></Td>
              <Td>{team.estimatedSize ?? '-'}</Td>
              <Td pl={0} pr={3}><EditMenu team={team} {...props} /></Td>
            </Tr>
          );
        })
      }
    </Tbody>
  );
}

export default function TeamsTable(props: Props) {
  return (
    <TableContainer w="100%">
      <Table>
        <Header />
        <Body {...props} />
      </Table>
    </TableContainer>
  );
}