import { Box } from '@chakra-ui/react';
import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { TaskJSON } from '@jurnee/common/src/entities/Task';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  task: TaskJSON;
  onDelete(task: TaskJSON): void;
}

export function TaskDotMenu({ task, onDelete }: Props) {
  const { t } = useTranslation('tasks', { keyPrefix: 'table.dotMenu' });

  return (
    <Box onClick={e => e.stopPropagation()}>
      <DotMenu size="sm" color="black" placement="bottom-end">
        <ConfirmModal
          title={t('deleteConfirmation.title')}
          message={<Trans i18nKey="tasks:table.dotMenu.deleteConfirmation.message" values={{ taskName: task.name }} />}
          buttonLabel={t('delete')}
          onConfirm={() => onDelete(task)}
        >
          <MenuItemWrapper icon="trash" label={t('delete')} isDisabled={task.ownerType === 'JURNEE'} />
        </ConfirmModal>
      </DotMenu>
    </Box>
  );
}