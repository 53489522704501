import { BookingCreateBody } from '@jurnee/common/src/dtos/bookings';
import BookingEntity, { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BudgetEntity } from '@jurnee/common/src/entities/Budget';
import TeamEntity from '@jurnee/common/src/entities/Team';
import { UserDetails } from '@jurnee/common/src/entities/User';
import { Expand, List } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import bookingsApi from '../../api/bookings';
import companyApi from '../../api/company';
import userApi from '../../api/users/bookings';
import { RootState } from '../state';
import { showToast } from '../toasts/toasts.thunks';

export type BookingScope = 'user' | 'company';

export type BookingsFiltersParams = {
  scope: BookingScope;
  searchParams: URLSearchParams;
}

export type BookingCreatePayload = {
  body: BookingCreateBody;
  onError?: (error: Error) => void;
  onSuccess?: (booking: Expand<BookingJSON, BookingRelationshipsJSON>) => void;
}

export interface BookingUpdatePayload {
  id: BookingJSON['id'];
  data: {
    budgetId?: BudgetEntity['id'];
    teamId?: TeamEntity['id'];
    name?: BookingEntity['name'];
  };
}

async function getBookingsByScope(user: UserDetails, { scope, searchParams }: BookingsFiltersParams): Promise<List<BookingJSON, BookingRelationshipsJSON>> {
  return scope === 'user' ? userApi.getBookingsByUser(user.id, searchParams) : companyApi.getBookingsByCompany(user.companyId, searchParams);
}

const getBookingsThunk = createAsyncThunk<List<BookingJSON, BookingRelationshipsJSON>, BookingsFiltersParams, { state: RootState }>('GET_BOOKINGS_THUNK', async (params, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();

    const bookings = await getBookingsByScope(user, params);

    return bookings;
  } catch (e) {
    thunkAPI.dispatch(showToast({ title: `An error occurred while retrieving your bookings`, status: 'error' }));

    return thunkAPI.rejectWithValue(e.message);
  }
});

export const getBookings = createAsyncThunk<List<BookingJSON, BookingRelationshipsJSON>, BookingsFiltersParams, { state: RootState }>('BOOKINGS_GET', async (params, thunkAPI) => {
  return thunkAPI.dispatch(getBookingsThunk(params)).unwrap();
});

export const getPastBookings = createAsyncThunk<List<BookingJSON, BookingRelationshipsJSON>, BookingsFiltersParams, { state: RootState }>('BOOKINGS_PAST_GET', async (params, thunkAPI) => {
  return thunkAPI.dispatch(getBookingsThunk(params)).unwrap();
});

export const createBooking = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, BookingCreatePayload, { state: RootState }>('BOOKINGS_CREATE', async ({ body, onSuccess, onError }, thunkAPI) => {
  try {
    const { user } = thunkAPI.getState();

    const booking = await userApi.createBooking(user.id, body);

    onSuccess && onSuccess(booking);

    return booking;
  } catch (err) {
    onError && onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateBooking = createAsyncThunk<BookingJSON, BookingUpdatePayload, { state: RootState }>('BOOKING_UPDATE', async ({ id, data }, thunkAPI) => {
  try {
    const booking = await bookingsApi.updateBooking(id, data);

    return booking;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});