{
  "approvalRequests": "Approval requests",
  "bookings": "My events",
  "company": {
    "billing": "Billing",
    "people": "People",
    "settings": "Settings"
  },
  "explore": "Explore",
  "newEvent": "Create a new event",
  "support": "Need help? Let's chat!",
  "tasks": "My tasks",
  "userCard": {
    "favorites": "My favorites",
    "lists": "My lists",
    "logout": "Logout",
    "settings": "Settings"
  }
}