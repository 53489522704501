import { Box, Text } from '@chakra-ui/react';
import { formatPrice } from '@jurnee/common/src/utils/prices';
import { Trans, useTranslation } from 'react-i18next';
import { DEFAULT_PAY_VIA_JURNEE_FEE_RATE, DEFAULT_TARGET_CURRENCY } from 'src/utils/booking';

interface Props {
  total: number;
  currency: string;
  rate: number;
}

export function FeeDescription({ total, currency, rate }: Props) {
  const { t } = useTranslation('booking', { keyPrefix: 'modals.payViaJurnee.fees' });

  return (
    <Box>
      <Text fontSize={16} fontWeight={700}>{t('label')}</Text>

      <Text>
        <Trans
          i18nKey="booking:modals.payViaJurnee.fees.description"
          values={{ feeRate: DEFAULT_PAY_VIA_JURNEE_FEE_RATE * 100 }}
        />
      </Text>

      {
        currency !== DEFAULT_TARGET_CURRENCY &&
          <Text>
            <Trans
              i18nKey="booking:modals.payViaJurnee.fees.conversion"
              values={{ sourceId: currency, targetId: DEFAULT_TARGET_CURRENCY, rate: rate.toFixed(2) }}
            />
          </Text>
      }

      {
        total > 0 &&
          <Text>
            <Trans
              i18nKey="booking:modals.payViaJurnee.fees.totalPrice"
              values={{ total: formatPrice(total, DEFAULT_TARGET_CURRENCY) }}
            />
          </Text>
      }
    </Box>
  );
}