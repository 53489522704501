import { api } from '@jurnee/common/src/browser/Api';
import { TeamUpsertBody } from '@jurnee/common/src/dtos/teams';
import { Team, TeamJSON } from '@jurnee/common/src/entities/Team';
import { MostFavoriteList } from '@jurnee/common/src/entities/UserFavoriteExperience';
import { List } from '@jurnee/common/src/serializers';

export default {
  getTeams(): Promise<List<TeamJSON, never>> {
    return api.get(`teams`);
  },
  createTeam(team: TeamUpsertBody): Promise<TeamJSON> {
    return api.post(`teams`, team);
  },
  updateTeam(teamId: Team['id'], data: TeamUpsertBody): Promise<TeamJSON> {
    return api.put(`teams/${teamId}`, data);
  },
  deleteTeam(teamId: Team['id']): Promise<TeamJSON> {
    return api.delete(`teams/${teamId}`);
  },
  getFavoriteExperiences(teamId: Team['id']): Promise<MostFavoriteList> {
    return api.get(`teams/${teamId}/favorite-experiences`);
  }
};