{
  "price": {
    "perPers": "/ pers",
    "inTotal": "in total",
    "priceUponRequest": "Make a request"
  },
  "form": {
    "commentPlaceholder": "Add any useful information",
    "next": "Next ->",
    "steps": {
      "information": {
        "tabLabel":  "1. INFORMATION",
        "quantity": {
          "label": "Quantity"
        },
        "participants": {
          "label": "Participants",
          "error": "This package is available for a maximum of {{max}} participants"
        },
        "date": {
          "label": "Date"
        },
        "dateApprox": {
          "label": "Date (approx.)"
        },
        "time": {
          "label": "Start time",
          "placeholder": "Select a time"
        },
        "timezone": {
          "label": "Select a city (for the timezone)"
        },
        "city": {
          "label": "Select a city"
        },
        "language": {
          "label": "Language",
          "placeholder": "Select language"
        },
        "package": {
          "label": "Choose a package",
          "customRequest": {
            "label": "Make a custom request",
            "labelInfo": "Price upon request",
            "description": "Add all the details of your request in the next step and we'll get back to you within 48 hours."
          }
        }
      },
      "event": {
        "tabLabel": "2. COMMENT & SEND",
        "addTo": "Add to:",
        "existingEvent": {
          "label": "An existing event",
          "placeholder": "Select an event"
        },
        "newEvent": {
          "label": "A new event",
          "name": {
            "label": "Name your event",
            "placeholder": "Give a name to your event"
          },
          "budget": {
            "label": "Which budget is it assigned to?",
            "placeholder": "Select a budget"
          }
        },
        "comment": {
          "label": "Add any useful information",
          "labelRequired": "Add as much detail as possible about your request",
          "placeholder": "Please make sure to add any detail that you think is relevant.",
          "placeholderRequired": "Please make sure to add any detail that you think is relevant. The more info you give us, the better the proposal you receive!"
        }
      }
    },
    "toasts": {
      "createBooking": {
        "error": "An error occurred while booking the event",
        "success": "Your booking has been successfully created"
      },
      "createBookingItems": {
        "error": "An error occurred while adding experience to the event",
        "success": "Your booking has been successfully updated"
      }
    }
  },
  "genericConcept": "This page showcases a <b>generic concept</b> of activity for inspirational purposes. To receive <b>tailor-made proposals</b> from partners and see their specific locations, please Make a custom request by filling out the form below !",
  "buttons": {
    "addToCollection": "Add to a list",
    "seeAllPhotos": "See all photos"
  },
  "specs": {
    "durationInMin": "{{maxDuration}} min",
    "participants": "up to {{maxParticipants}} people",
    "customRequestInspiration": "Custom request inspiration",
    "recommendedByCompany": "Recommended by your company"
  },
  "details": {
    "description": {
      "title": "What's in for you",
      "highlights": "Highlights:",
      "cuisines": "Cuisines:",
      "categories": "Categories:"
    },
    "included": {
      "title": "What's included"
    },
    "requirements": {
      "title": "What you'll need"
    },
    "partner": {
      "hostedBy": "Hosted by",
      "reviews": "<rating>{{rating}}</rating> <count>based on {{count}} reviews</count>"
    },
    "map": {
      "title": "Where you'll be"
    },
    "reviews": {
      "title": "Reviews",
      "button": "Show all {{count}} reviews"
    },
    "importantInfo": {
      "title": "Important info",
      "wheelchairAccessible": "Wheelchair accessible",
      "notWheelchairAccessible": "Not wheelchair accessible",
      "notPregnantAccessible": "Not recommended for pregnant women"
    },
    "cancellationPolicy": {
      "title": "Cancellation policy",
      "content": "Cancel up to 7 days before the Experience start time for a full refund, or within 24 hours of booking as long as the booking is made more than 48 hours before the start time."
    },
    "similarExperiences": {
      "title": "Similar experiences"
    }
  },
  "drawers": {
    "photos": {
      "title": "Photos"
    },
    "reviews": {
      "title": "Reviews"
    }
  }
}