import { BookingCreateBody, BookingItemUpdateBody } from '@jurnee/common/src/dtos/bookings';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { Expand } from '@jurnee/common/src/serializers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import bookingsApi from '../../api/bookings';
import { RootState } from '../state';

export type BookingItemsCreatePayload = {
  bookingId: BookingJSON['id'];
  body: Pick<BookingCreateBody, 'bookingsItems'>;
  onError?: (error: Error) => void;
  onSuccess?: (booking: BookingJSON) => void;
}

export interface BookingItemDeletePayload {
  bookingId: BookingJSON['id'];
  bookingItemId: BookingItemJSON['id'];
}

export interface BookingItemUpdatePayload {
  bookingId: BookingJSON['id'];
  bookingItemId: BookingItemJSON['id'];
  data: BookingItemUpdateBody;
  onError(error: unknown): void;
  onSuccess(booking: Expand<BookingJSON, BookingRelationshipsJSON>): void;
}

export const createBookingItems = createAsyncThunk<BookingJSON, BookingItemsCreatePayload, { state: RootState }>('BOOKING_ITEMS_CREATE', async ({ bookingId, body, onSuccess, onError }, thunkAPI) => {
  try {
    const booking = await bookingsApi.createBookingItems(bookingId, body);
    onSuccess && onSuccess(booking);
    return booking;
  } catch (err) {
    onError && onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const updateBookingItem = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, BookingItemUpdatePayload, { state: RootState }>('BOOKING_ITEM_UPDATE', async ({ bookingId, bookingItemId, data, onSuccess, onError }, thunkAPI) => {
  try {
    const booking = await bookingsApi.updateBookingItem(bookingId, bookingItemId, data);
    onSuccess(booking);
    return booking;
  } catch (err) {
    onError(err);
    return thunkAPI.rejectWithValue(err.message);
  }
});

export const deleteBookingItem = createAsyncThunk<Expand<BookingJSON, BookingRelationshipsJSON>, BookingItemDeletePayload, { state: RootState }>('BOOKING_ITEM_DELETE', async ({ bookingId, bookingItemId }, thunkAPI) => {
  try {
    const booking = await bookingsApi.deleteBookingItem(bookingId, bookingItemId);

    return booking;
  } catch (err) {
    return thunkAPI.rejectWithValue(err.message);
  }
});