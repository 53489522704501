import { Circle, Heading, StackProps, Text, VStack } from '@chakra-ui/react';
import { Icon, IconKey } from './Icon';

export interface Props {
  icon: IconKey;
  heading: string;
  description: string;
  children?: React.ReactNode;
}

export function EmptyState({ icon, heading, description, children, ...inheritedProps }: Props & StackProps) {
  return (
    <VStack spacing={4} {...inheritedProps}>
      <Circle minW="48px" minH="48px" bgColor="gray.50" border="1px solid" borderColor="gray.300">
        <Icon icon={icon} color="gray.300" size={6} />
      </Circle>

      <VStack spacing={3}>
        <Heading size="sm" lineHeight="16px">{heading}</Heading>

        <Text maxW={420} color="gray.400" whiteSpace="pre-line" textAlign="center">
          { description }
        </Text>
      </VStack>

      { children }
    </VStack>
  );
}