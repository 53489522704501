import { Box, Heading, HStack, LinkBox, LinkOverlay, Text, VStack } from '@chakra-ui/react';
import { RatingStars } from '@jurnee/common/src/components/RatingStars';
import { BookingJSON, BookingRelationshipsJSON } from '@jurnee/common/src/entities/Booking';
import { BookingItemJSON } from '@jurnee/common/src/entities/BookingItem';
import { CurrencyExchangeRates } from '@jurnee/common/src/entities/Currency';
import { sortByDate } from '@jurnee/common/src/utils/arrays';
import { getBookingName, getBookingStatus, getMainBookingRelation, getOrganizerLabel, PLACEHOLDER_PATH } from '@jurnee/common/src/utils/bookings';
import { getItemTimezone } from '@jurnee/common/src/utils/bookingsItems';
import { getCdnImageUrl } from '@jurnee/common/src/utils/core';
import { formatDate } from '@jurnee/common/src/utils/dates';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PriceInUserCurrency from 'src/components/PriceInUserCurrency';
import { BookingStatus } from 'src/components/Status/BookingStatus';
import { filterRelationshipsByBookingId, getAvgBookingReviewsRating, getBookingImagePaths, getBookingPriceRange } from '../../utils/booking';
import { BookingItemCardLine } from './BookingCardLine';

interface Props extends WithTranslation {
  key: number;
  booking: BookingJSON;
  relationships: Pick<BookingRelationshipsJSON, 'approvalRequests' | 'bookingsInvoices' | 'bookingsReviews' | 'externalCosts' | 'quotes' | 'propositionsGroups' | 'users'>
  currencies: CurrencyExchangeRates;
}

class BookingCard extends React.Component<Props> {

  get relationships() {
    return filterRelationshipsByBookingId(this.props.booking.id, this.props.relationships);
  }

  get bookingStatus() {
    const bookingStatus = getBookingStatus(this.props.booking, this.relationships);

    return <BookingStatus status={bookingStatus}/>;
  }

  get date() {
    try {
      const mainRelation = getMainBookingRelation(this.props.booking);

      return formatDate(new Date(mainRelation.from), { timeZone: getItemTimezone(mainRelation) });
    } catch (err) {
      return null;
    }
  }

  get subtitle() {
    if (this.relationships.bookingsReviews.length > 0) {
      const avgRating = getAvgBookingReviewsRating(this.relationships.bookingsReviews);

      return (
        <HStack spacing={2}>
          <RatingStars rating={avgRating} />
          <Text fontSize={14} lineHeight="16px" color="gray.400">
            <Trans i18nKey='bookings:bookingCard.rating' values={{ rating: avgRating.toFixed(1) }} />
          </Text>
        </HStack>
      );
    }

    return (
      <Text fontSize={14} color="gray.400" lineHeight="16px" noOfLines={1}>
        {this.date}
      </Text>
    );
  }

  get price() {
    const range = getBookingPriceRange(
      this.relationships,
      this.props.currencies,
      this.relationships.bookingsInvoices[0]?.currency || 'EUR'
    );

    if (range.from === range.to) {
      return (
        <Heading size="md" flexShrink={0} textAlign="right">
          <PriceInUserCurrency value={range.from} currency="EUR"/>
        </Heading>
      );
    }

    return (
      <Heading size="md" flexShrink={0} textAlign="right">
        <PriceInUserCurrency value={range.from} currency="EUR"/> - <PriceInUserCurrency value={range.to} currency="EUR"/>
      </Heading>
    );
  }

  get heading() {
    const name = getBookingName(this.props.booking);

    return (
      <Heading size="md" noOfLines={1}>
        <LinkOverlay to={`/bookings/${this.props.booking.id}`} as={Link}>
          {name}
        </LinkOverlay>
      </Heading>
    );
  }

  getImage(path: string, key = 0) {
    return (
      <Box
        key={key}
        h="100%"
        minW={180}
        bgImage={`linear-gradient(rgba(30, 0, 45, 0.30), rgba(30, 0, 45, 0.30)), url('${getCdnImageUrl(path)}');`}
        bgColor="rgb(30, 0, 45)"
        bgPosition="center"
        bgSize="cover"
        alignSelf="stretch"
      />
    );
  }

  get images() {
    const paths = getBookingImagePaths(this.props.booking);

    return paths.slice(0,4).map((path, idx) => this.getImage(path, idx));
  }

  get imagesCard() {
    return (
      <VStack
        minW={180}
        alignSelf="stretch"
        borderRadius="4px 0 0 4px"
        overflow="hidden"
        bgColor="white"
        spacing="1px"
      >
        { this.images.length === 0 ? this.getImage(PLACEHOLDER_PATH) : this.images }
      </VStack>
    );
  }

  get bookingItemCardLines() {
    return sortByDate(this.props.booking.bookingsItems, 'from')
      .map((item: BookingItemJSON) => <BookingItemCardLine key={item.id} item={item} />);
  }

  get organizerLabel() {
    return getOrganizerLabel(this.props.booking.bookingsOrganizers, this.props.relationships.users);
  }

  render() {
    return (
      <LinkBox w="100%">
        <HStack h="100%" w="100%" spacing={0}>
          {this.imagesCard}
          <Box w="100%" border="1px solid" borderColor="blue.50" borderRadius="0 4px 4px 0" borderLeft={0} bg="white" px={5}>
            <HStack w="100%" justifyContent="space-between" mt={5}>
              {this.heading}
              {this.price}
            </HStack>

            <HStack w="100%" justifyContent="space-between" mt={1}>
              {this.subtitle}
              {this.bookingStatus}
            </HStack>

            <HStack spacing={0} borderTop="1px solid" borderColor="blue.50" mt={4} py={3}>
              <Box w="33.33%">
                <Text fontWeight={700}>{this.props.t('bookingCard.organizer')}</Text>
                <Text color="gray.400" noOfLines={1}>{this.organizerLabel}</Text>
              </Box>

              <Box w="33.33%" pl={5} borderLeft="1px solid" borderLeftColor="blue.50">
                <Text fontWeight={700}>{this.props.t('bookingCard.team')}</Text>
                <Text color="gray.400" noOfLines={1}>{this.props.booking.team ? this.props.booking.team.name : '-'}</Text>
              </Box>

              <Box w="33.33%" pl={5} borderLeft="1px solid" borderLeftColor="blue.50">
                <Text fontWeight={700}>{this.props.t('bookingCard.budget')}</Text>
                <Text color="gray.400" noOfLines={1}>{this.props.booking.budget ? this.props.booking.budget.name : '-'}</Text>
              </Box>
            </HStack>

            { this.bookingItemCardLines }
          </Box>
        </HStack>
      </LinkBox>
    );
  }

}

export default withTranslation('bookings')(BookingCard);