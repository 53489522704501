import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import approvalProcesses from './approvalProcesses/approvalProcesses.store';
import approvalRequests from './approvalRequests/approvalRequests.store';
import auth from './auth/auth.store';
import bookingDetails from './bookingDetails/bookingDetails.store';
import bookingParticipants from './bookingParticipants/bookingParticipants.store';
import bookings from './bookings/bookings.store';
import bookingsInvoices from './bookingsInvoices/bookingsInvoices.store';
import bootstrap from './bootstrap/bootstrap.store';
import budgetBreakdowns from './budgetBreakdowns/budgetBreakdowns.store';
import budgets from './budgets/budgets.store';
import collectionDetails from './collectionDetails/collectionDetails.store';
import collections from './collections/collections.store';
import company from './company/company.store';
import companyRecommendedPartners from './companyRecommendedPartners/companyRecommendedPartners.store';
import currencies from './currencies/currencies.store';
import employees from './employees/employees.store';
import entities from './entities/entities.store';
import experienceDetails from './experienceDetails/experienceDetails.store';
import experienceDetailsStats from './experienceDetailsStats/experienceDetailsStats.store';
import experiences from './experiences/experiences.store';
import experiencesRatings from './experiencesRatings/experiencesRatings.store';
import externalCosts from './externalCosts/externalCosts.store';
import geolocation from './geolocation/geolocation.store';
import languages from './languages/languages.store';
import locations from './locations/locations.store';
import places from './places/places.store';
import providerDiscounts from './providerDiscounts/providerDiscounts.store';
import reviewDetails from './reviewDetails/reviewDetails.store';
import signup from './signup/signup.store';
import tags from './tags/tags.store';
import teamBudgetBreakdowns from './teamBudgetBreakdowns/teamBudgetBreakdowns.store';
import teams from './teams/teams.store';
import user from './user/user.store';
import userBudgetBreakdowns from './userBudgetBreakdowns/userBudgetBreakdowns.store';
import userFavoriteExperiences from './userFavoriteExperiences/userFavoriteExperiences.store';
import userStats from './userStats/userStats.store';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = configureStore({
  reducer: {
    approvalProcesses,
    approvalRequests,
    auth,
    bootstrap,
    bookingDetails,
    bookingParticipants,
    bookings,
    bookingsInvoices,
    budgets,
    budgetBreakdowns,
    collections,
    collectionDetails,
    company,
    companyRecommendedPartners,
    currencies,
    employees,
    entities,
    experienceDetails,
    experienceDetailsStats,
    experiences,
    experiencesRatings,
    externalCosts,
    geolocation,
    languages,
    locations,
    places,
    providerDiscounts,
    reviewDetails,
    signup,
    tags,
    teamBudgetBreakdowns,
    teams,
    user,
    userFavoriteExperiences,
    userBudgetBreakdowns,
    userStats
  },
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer)
});

type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;