import { List, ListItem, Table, TableContainer, Tbody, Td, Th, Thead, Tr, UseToastOptions } from '@chakra-ui/react';
import { ConfirmModal } from '@jurnee/common/src/components/ConfirmModal';
import { DotMenu } from '@jurnee/common/src/components/DotMenu';
import { MenuItemWrapper } from '@jurnee/common/src/components/MenuItemWrapper';
import { ApprovalProcessJSON } from '@jurnee/common/src/entities/ApprovalProcess';
import { getUserLabel } from '@jurnee/common/src/utils/user';
import * as React from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ApprovalProcessDrawer from 'src/drawers/EditApprovalProcessDrawer';
import { ApprovalProcessDeletePayload, deleteApprovalProcess } from 'src/store/approvalProcesses/approvalProcesses.thunks';
import { showToast } from 'src/store/toasts/toasts.thunks';

interface OwnProps {
  approvalProcesses: ApprovalProcessJSON[];
}

interface DispatchProps {
  deleteApprovalProcess(data: ApprovalProcessDeletePayload): void;
  showToast(payload: UseToastOptions): void;
}

type Props = OwnProps & DispatchProps & WithTranslation;

class ApprovalProcessesTable extends React.Component<Props> {

  get header() {
    return (
      <Tr>
        <Th>{this.props.t('approval.table.name')}</Th>
        <Th>{this.props.t('approval.table.rules')}</Th>
        <Th>{this.props.t('approval.table.processNumber')}</Th>
        <Th>{this.props.t('approval.table.users')}</Th>
        <Th w={8}/>
      </Tr>
    );
  }

  getRules(approvalProcess: ApprovalProcessJSON) {
    if (approvalProcess.approvalRules.length === 0) {
      return '-';
    }

    const rules = approvalProcess.approvalRules.map(rule => {
      return <ListItem key={rule.id}>{this.props.t('approval.table.rulePrefix')} {getUserLabel(rule.approver)}</ListItem>;
    });

    return (
      <List py={2} listStyleType="none" spacing={2}>
        {rules}
      </List>
    );
  }

  getProcessNumberRequired(approvalProcess: ApprovalProcessJSON) {
    return this.props.t(approvalProcess.processNumberRequired ? 'yes' : 'no', { ns: 'common' });
  }

  onDelete = (id: ApprovalProcessJSON['id']) => {
    this.props.deleteApprovalProcess({
      id,
      onError: (err) => {
        const title = err instanceof Error ? err.message : this.props.t('approval.delete.toasts.error');
        this.props.showToast({ title, status: 'error' });
      },
      onSuccess: () => {
        this.props.showToast({ title: this.props.t('approval.delete.toasts.success'), status: 'success' });
      }
    });
  };

  get body() {
    return this.props.approvalProcesses.map((approvalProcess) => (
      <Tr key={approvalProcess.id}>
        <Td>{approvalProcess.name}</Td>
        <Td>{this.getRules(approvalProcess)}</Td>
        <Td>{this.getProcessNumberRequired(approvalProcess)}</Td>
        <Td>{approvalProcess.users.length}</Td>
        <Td pl={0} pr={3}>
          <DotMenu size="sm" color="black" placement="bottom-end">
            <ApprovalProcessDrawer approvalProcess={approvalProcess}>
              <MenuItemWrapper icon="edit" label={this.props.t('approval.edit.buttonLabel')} />
            </ApprovalProcessDrawer>
            <ConfirmModal
              title={this.props.t('approval.delete.title')}
              message={<Trans i18nKey='settings:approval.delete.text' values={{ name: approvalProcess.name }} />}
              buttonLabel={this.props.t('common:buttons.delete')}
              onConfirm={() => this.onDelete(approvalProcess.id)}
            >
              <MenuItemWrapper icon="trash" label={this.props.t('approval.delete.buttonLabel')} />
            </ConfirmModal>
          </DotMenu>
        </Td>
      </Tr>
    ));
  }

  render() {
    return (
      <TableContainer w="100%">
        <Table>
          <Thead>{this.header}</Thead>
          <Tbody>{this.body}</Tbody>
        </Table>
      </TableContainer>
    );
  }

}

const mapDispatchToProps: DispatchProps = {
  deleteApprovalProcess,
  showToast,
};

export default connect<null, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(withTranslation('settings')(ApprovalProcessesTable));