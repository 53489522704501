import { BudgetEntity, BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { DateRange, add, getEndOfHalfYear, getEndOfMonth, getEndOfQuarter, getEndOfYear, getStartOfHalfYear, getStartOfMonth, getStartOfQuarter, getStartOfYear } from '@jurnee/common/src/utils/dates';
import BookingEntity from '../entities/Booking';
import BookingInvoiceEntity from '../entities/BookingInvoice';
import { BudgetBreakdownEntity } from '../entities/BudgetBreakdown';
import { CurrencyExchangeRates } from '../entities/Currency';
import { getCommittedInvoices, getPendingInvoices } from './bookingInvoices';
import { getExternalCostsTotal } from './externalCosts';

export function getStartOfBudgetFrequency(frequency: BudgetEntity['frequency']): Date {
  const today = new Date();

  switch (frequency) {
  case 'MONTHLY':
    return getStartOfMonth(today);
  case 'QUARTERLY':
    return getStartOfQuarter(today);
  case 'SEMIANNUALLY':
    return getStartOfHalfYear(today);
  default:
    return getStartOfYear(today);
  }
}

export function getEndOfBudgetFrequency(frequency: BudgetEntity['frequency']): Date {
  const today = new Date();

  switch (frequency) {
  case 'MONTHLY':
    return getEndOfMonth(today);
  case 'QUARTERLY':
    return getEndOfQuarter(today);
  case 'SEMIANNUALLY':
    return getEndOfHalfYear(today);
  default:
    return getEndOfYear(today);
  }
}

export function getBudgetRenewedOn(frequency: BudgetJSON['frequency']): Date {
  const end = getEndOfBudgetFrequency(frequency);

  return add(end, 1);
}

export function getBookingsPendingInvoices(bookings: BookingEntity[]): BookingInvoiceEntity[] {
  return bookings.flatMap(({ bookingsInvoices }) => getPendingInvoices(bookingsInvoices));
}

export function getBookingsCommittedInvoices(bookings: BookingEntity[], range: DateRange): BookingInvoiceEntity[] {
  return bookings.flatMap(({ bookingsInvoices }) => getCommittedInvoices(bookingsInvoices, range));
}

export function getBookingsExternalCostsTotal(bookings: Pick<BookingEntity, 'externalCosts'>[], currencies: CurrencyExchangeRates, targetId: string, range: DateRange) {
  return bookings.reduce((total, { externalCosts }) => {
    return total + getExternalCostsTotal(externalCosts, currencies, targetId, range);
  }, 0);
}

export function getBudgetRemaining({ pending, spent, total }: Pick<BudgetBreakdownEntity, 'pending' | 'spent' | 'total'>): number {
  return total - spent - pending;
}

export function getUserBudgetRemaining({ spent, total }: { spent: number, total: number }) {
  return total - spent;
}

export function getBudgetBreakdownsTotal(budgetBreakdowns: BudgetBreakdownEntity[]): Pick<BudgetBreakdownEntity, 'pending' | 'spent' | 'total'> {
  return budgetBreakdowns.reduce((yearly, breakdown) => {
    yearly.total += breakdown.total;
    yearly.spent += breakdown.spent;
    yearly.pending += breakdown.pending;

    return yearly;
  }, {
    pending: 0,
    spent: 0,
    total: 0
  });
}