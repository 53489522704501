import { Box, HStack, Heading, Select, Spacer } from '@chakra-ui/react';
import { FETCH_STATUS } from '@jurnee/common/src/browser/State';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BudgetJSON } from '@jurnee/common/src/entities/Budget';
import { TeamJSON } from '@jurnee/common/src/entities/Team';
import { isAdmin } from '@jurnee/common/src/utils/user';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EmptyState from 'src/components/EmptyState';
import { useAppDispatch } from 'src/store';
import { getActionRequiredBookingsSelector, getBookingsSelector, getConfirmedBookingsSelector, getDraftBookingsSelector, getExternalCostsBookingsSelector, getPendingBookingsSelector } from 'src/store/bookings/bookings.selectors';
import { BookingScope, getBookings, getPastBookings } from 'src/store/bookings/bookings.thunks';
import { getUserBudgets } from 'src/store/budgets/budgets.thunks';
import { getCurrenciesSelector } from 'src/store/currencies/currencies.selectors';
import { getCurrenciesThunk } from 'src/store/currencies/currencies.thunk';
import { getUserTeams } from 'src/store/teams/teams.thunks';
import { getUserSelector } from 'src/store/user/user.selectors';
import { BookingsTabs as Tabs } from 'src/utils/booking';
import ActionRequiredCard from './ActionRequiredCard';
import BookingsList from './BookingsList';
import BookingsTabs, { TabItem } from './BookingsTabs';
import { BudgetsOverview } from './BudgetsOverview';
import BookingsCalendar from './Calendar';
import ViewSelector, { ViewKey } from './ViewSelector';

export default function Bookings() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('bookings');

  const user = useSelector(getUserSelector);
  const bookings = useSelector(getBookingsSelector);
  const currencies = useSelector(getCurrenciesSelector);

  const [areBudgetsLoading, setAreBudgetsLoading] = useState(true);
  const [areTeamsLoading, setAreTeamsLoading] = useState(true);
  const [areBookingsLoading, setAreBookingsLoading] = useState(true);
  const [areCurrenciesLoading, setAreCurrenciesLoading] = useState(true);

  const isLoading = areBudgetsLoading || areTeamsLoading || areBookingsLoading || areCurrenciesLoading;

  const filteredBookings = {
    all: bookings.list,
    confirmed: useSelector(getConfirmedBookingsSelector),
    pending: useSelector(getPendingBookingsSelector),
    actionRequired: useSelector(getActionRequiredBookingsSelector),
    draft: useSelector(getDraftBookingsSelector),
    externalCosts: useSelector(getExternalCostsBookingsSelector),
  };

  const [currentTab, setCurrentTab] = useState<Tabs>('all');
  const [currentScope, setCurrentScope] = useState<BookingScope>(isAdmin(user) ? 'company' : 'user');
  const [currentView, setCurrentView] = useState<ViewKey>('list');

  const TABS: Tabs[] = [
    'all',
    'confirmed',
    'pending',
    'actionRequired',
    'draft',
    'externalCosts',
    'past'
  ];

  const [budgetIds, setBudgetIds] = useState<BudgetJSON['id'][]>([]);
  const [teamIds, setTeamIds] = useState<TeamJSON['id'][]>([]);

  async function fetchUserBudgets() {
    setAreBudgetsLoading(true);
    const { list } = await dispatch(getUserBudgets()).unwrap();
    setBudgetIds(list.map(budget => budget.id));
    setAreBudgetsLoading(false);
  }

  async function fetchUserTeams() {
    setAreTeamsLoading(true);
    const { list } = await dispatch(getUserTeams()).unwrap();
    setTeamIds(list.map(team => team.id));
    setAreTeamsLoading(false);
  }

  async function fetchCurrencies() {
    setAreCurrenciesLoading(true);
    await dispatch(getCurrenciesThunk()).unwrap();
    setAreCurrenciesLoading(false);
  }

  useEffect(() => {
    getBookingsByScope(currentScope);
    fetchUserTeams();
    fetchUserBudgets();
    fetchCurrencies();
  }, []);

  function handleOwnerChange({ target }: React.ChangeEvent<HTMLSelectElement>) {
    const newScope = target.value as BookingScope;

    setCurrentTab('all');
    setCurrentView('list');
    setCurrentScope(newScope);
    getBookingsByScope(newScope);
  }

  function scopeSwitch() {
    if (!isAdmin(user)) {
      return null;
    }

    return (
      <Select name="bookings-scope" bg="white" fontSize={14} borderRadius={4} value={currentScope} onChange={handleOwnerChange} w={270} h={8}>
        <option value="company">{t('header.bookingsScope.companyBookings')}</option>
        <option value="user">{t('header.bookingsScope.myBookings')}</option>
      </Select>
    );
  }

  async function getBookingsByScope(scope: BookingScope) {
    setAreBookingsLoading(true);
    const after = Date.now().toString();
    const searchParams = new URLSearchParams({ after });

    await dispatch(getBookings({ scope, searchParams })).unwrap();

    setAreBookingsLoading(false);
  }

  function getPastBookingsByScope(scope: BookingScope) {
    const before = Date.now().toString();
    const searchParams = new URLSearchParams({ before });

    dispatch(getPastBookings({ scope, searchParams }));
  }

  function getFilteredBookings(tab: Tabs) {
    if (tab === 'past') {
      const { list, relationships, status } = bookings.past;
      const isLoading = status !== FETCH_STATUS.FETCHED;

      return { list, relationships, isLoading };
    }

    return {
      list: filteredBookings[tab],
      relationships: bookings.relationships,
      isLoading
    };
  }

  function handleTabChange(tab: Tabs) {
    setCurrentTab(tab);

    if (tab === 'past') {
      setCurrentView('list');
      getPastBookingsByScope(currentScope);
    }
  }

  function actionRequiredCard() {
    if (currentTab !== 'all') {
      return null;
    }

    const count = filteredBookings['actionRequired'].length;

    if (count === 0) {
      return null;
    }

    return <ActionRequiredCard count={count} onClick={() => setCurrentTab('actionRequired')} />;
  }

  function renderBookings(tab: Tabs) {
    const { list, relationships, isLoading } = getFilteredBookings(tab);

    if (isLoading) {
      return <Loader h={400} />;
    }

    if (list.length === 0) {
      const i18nKey = tab === 'past' ? 'past' : 'upcoming';

      return <EmptyState
        imagePath="/assets/illustrations/calendar_checked.svg"
        description={t(`emptyState.${i18nKey}.description`)}
        heading={t(`emptyState.${i18nKey}.heading`)}
      />;
    }

    const bookingsList = (
      <BookingsList tab={tab} bookings={list} relationships={relationships} currencies={currencies}>
        { actionRequiredCard() }
      </BookingsList>
    );

    const bookingsCalendar = <BookingsCalendar tab={tab} bookings={list} relationships={relationships} />;

    return (
      <Box w="100%" position="relative">
        { tab !== 'past' && <ViewSelector views={['list', 'calendar']} currentView={currentView} onViewChange={setCurrentView} position="absolute" top={0} right={0} /> }

        { currentView === 'list' ? bookingsList : bookingsCalendar }
      </Box>
    );
  }

  function getPanelContent(tab: Tabs) {
    const hasBudgetsOverview = teamIds.length > 0 || budgetIds.length > 0;

    return (
      <HStack alignItems="flex-start" spacing={8}>
        { hasBudgetsOverview && <BudgetsOverview budgetIds={budgetIds} teamIds={teamIds} /> }

        { renderBookings(tab) }
      </HStack>
    );
  }

  function getBookingsCount(tab: Tabs) {
    if (tab === 'past') {
      return 0;
    }

    return filteredBookings[tab].length;
  }

  function tabItems(): TabItem[] {
    const tabItems = TABS.map(tab => {
      return {
        label: t(`header.tabs.${tab}`),
        count: getBookingsCount(tab),
        key: tab,
        panelContent: getPanelContent(tab)
      };
    });

    return tabItems.filter(({ key, count }) => ['all', 'past'].includes(key) || count > 0);
  }

  function header() {
    const headerProps = areBookingsLoading ? { borderBottom: '1px solid', borderColor: 'blue.50' } : {};

    return (
      <HStack p={8} w="100%" bg="white" spacing={4} {...headerProps}>
        <Heading>{t('header.title')}</Heading>
        <Spacer />

        { scopeSwitch() }
      </HStack>
    );
  }

  function content() {
    if (isLoading) {
      return <Loader />;
    }

    return <BookingsTabs
      tabItems={tabItems()}
      currentTab={currentTab}
      onChange={handleTabChange}
    />;
  }

  return (
    <main>
      { header() }
      { content() }
    </main>
  );

}