import { Heading, Image, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';

type OwnProps = {
  imagePath: string;
  heading: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
}

export default class EmptyState extends React.Component<OwnProps> {

  get image() {
    if (!this.props.imagePath) {
      return null;
    }

    return <Image src={this.props.imagePath} w="100px" h="100px" />;
  }

  get description() {
    if (!this.props.description) {
      return null;
    }

    return (
      <Text w="80%" maxW="600px" fontSize="14px" textAlign="center" color="gray.400" whiteSpace="pre-line">
        {this.props.description}
      </Text>
    );
  }

  render() {
    return (
      <VStack
        p={16}
        spacing={4}
        w="100%"
        justifyContent="center"
        alignItems="center"
        bgColor="white"
        border="1px solid"
        borderColor="blue.50"
        borderRadius={4}
      >
        {this.image}

        <VStack w="100%" spacing={4}>
          <Heading size="md">{this.props.heading}</Heading>
          {this.description}
        </VStack>

        {this.props.children}
      </VStack>
    );
  }

}